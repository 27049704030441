/******************************

INDEX:

    00 - Base - Typography
    01 - Base - Variables
    02 - Base - Mixins

    03 - Utilities - Helper
    04 - Utilities - Color

    10 - Components - Button
    11 - Components - Slider
    12 - Components - Animation
    13 - Components - Navigation
    14 - Components - Banner
    15 - Components - Product
    16 - Components - Shipping
    17 - Components - Product Tab
    18 - Components - Form
    19 - Components - Social Link
    20 - Components - Modal
    21 - Components - Brand
    22 - Components - Breadcrumb

    25 - Section - Header
    26 - Section - Footer

    29 - Pages - Shop
    30 - Pages - Single Product
    31 - Pages - Blog
    32 - Pages - My Account
    33 - Pages - Login | Register
    34 - Pages - Wishlist
    35 - Pages - Cart
    36 - Pages - Checkout
    37 - Pages - Compare
    38 - Pages - Contact
    39 - Pages - About Us
    40 - Pages - FAQ
    41 - Pages - 404

******************************/
/*
    Primary Color:            #ffc400;
    Body Text Color:          #999999;
    Heading Color:            #333333;
    Border Color:             #e5e5e5;
    Body Font Family:         'Work Sans', sans-serif;

*/
@import url("https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700,800,900&display=swap");
/*----------------------------------------*/
/*  00 - Base - Typography
/*----------------------------------------*/
body {
  font-family: "Work Sans", sans-serif;
  color: #999999;
  font-size: 14px;
  line-height: 24px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Work Sans", sans-serif;
  color: #333333;
  line-height: 1;
  font-weight: 700;
}

p {
  font-family: "Work Sans", sans-serif;
  color: #999999;
  font-size: 14px;
  line-height: 24px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input {
  color: #888888;
}

input::placeholder {
  color: #888888;
}

._keyfocus :focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
  outline: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

.img-full {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
}

a {
  color: #999999;
}

a:hover {
  color: #ffc400 !important;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover {
  color: #ffc400;
}

a,
a:hover,
a:focus,
a:active,
button,
button:focus,
input,
select,
textarea,
.slick-slide {
  outline: none;
}

button {
  cursor: pointer;
  border: none;
  padding: 0;
  outline: none;
  background: transparent;
}

/* ---Uren's Blog Section Default Youtube & Soundcloud Height--- */
.grid-view_area .embed-responsive:before {
  padding-top: 64.50%;
}

.column-three_area .embed-responsive:before {
  padding-top: 64.50%;
}

/*----------------------------------------*/
/*  03 - Utilities - Helper
/*----------------------------------------*/
/* ---Uren's Custom Container--- */
.container-fluid {
  padding: 0 75px;
}

@media (max-width: 1599px) {
  .container-fluid {
    padding: 0 30px;
  }
}

/* ---Uren's Preloader---*/
.loading {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  z-index: 9999999999999;
}

.loading .middle {
  position: absolute;
  top: 50%;
  width: 100%;
}

.loading .middle .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.loading .middle .lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #ffc400;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading .middle .lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.loading .middle .lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.loading .middle .lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.loading .middle .lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

/* ---Uren's Product Sticker---*/
.sticker,
.sticker-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
  height: 20px;
  line-height: 21px;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  background: #ffc400;
  color: #ffffff;
  text-transform: uppercase;
  cursor: text;
  font-weight: 700;
}

.sticker-area-2 .sticker-2 {
  background: #ff4000;
  top: 0;
}

.sticker-area-2 .sticker-2:before {
  border-color: #ff4000 transparent transparent transparent;
}

.sticker-area-2 .sticker {
  top: 30px;
}

/* ---Uren's Section Title--- */
.section-title_area {
  text-align: center;
  margin: 0 auto;
}

.section-title_area > span {
  padding-bottom: 10px;
  display: block;
  font-size: 16px;
}

.section-title_area > h3 {
  font-size: 30px;
  margin-bottom: 0;
  padding-bottom: 50px;
  font-weight: 600;
}

@media (max-width: 479px) {
  .section-title_area > h3 {
    font-size: 22px;
  }
}

.section-title_area.bg--white {
  padding-top: 55px;
}

/* ---Uren's Spacing Between Slide Item & Other Related Stuff---*/
.slick-gutter-30 .slick-list {
  margin-left: -15px;
  margin-right: -15px;
}

.slick-gutter-30 .slick-list .slick-slide {
  padding-left: 15px;
  padding-right: 15px;
}

/* ---Slick Disabled Cursor Point--- */
.slick-disabled {
  cursor: not-allowed !important;
}

/* ---Only For Single Product Page | Spacing Between Slide Item--- */
.sp-images .slick-list {
  margin-left: 0;
}

.sp-images .slick-list .slick-slide {
  margin-left: 0;
}

/* ---Uren's Spacing Between Two Row---*/
.best-selling_slider .slick-active div:first-child .product-slide_item {
  border-bottom: 1px solid #e5e5e5;
}

/* ---Tab Content & Pane Fix--- */
.tab-content {
  width: 100%;
}

.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/* ---Uren's Rating Box Empty Star Color--- */
.rating-box > ul > li > i {
  color: #ffc400;
  font-size: 16px;
}

.rating-box > ul > li.silver-color > i {
  color: #bababa !important;
}

/* ---Uren's Global Overlay--- */
.global-overlay {
  background-color: rgba(51, 51, 51, 0.9);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  visibility: hidden;
}

.global-overlay.overlay-open {
  visibility: visible;
}

/* ---Uren's Tag--- */
.uren-tags_list li {
  display: inline-block;
  margin-bottom: 5px;
}

.uren-tags_list li a {
  font-size: 14px;
  border: 1px solid #e5e5e5;
  padding: 5px 15px;
  display: block;
  line-height: 20px;
}

.uren-tags_list li a:hover {
  color: #ffffff !important;
  background-color: #ffc400;
  border-color: #ffc400;
}

/* ---Uren's Pagination--- */
.uren-paginatoin-area {
  padding-top: 30px;
  display: flex;
  justify-content: center;
}

.uren-paginatoin-area .uren-pagination-box {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}

.uren-paginatoin-area .uren-pagination-box li {
  display: inline-block;
}

.uren-paginatoin-area .uren-pagination-box li > a {
  color: #333333;
  padding: 0 25px;
  display: block;
}

@media (max-width: 479px) {
  .uren-paginatoin-area .uren-pagination-box li > a {
    padding: 0 15px;
  }
}

.uren-paginatoin-area .uren-pagination-box.carlet-color {
  border: 2px solid #e52e06;
}

.uren-paginatoin-area .uren-pagination-box.carlet-color > li:last-child > a {
  border-right: 0;
}

.uren-paginatoin-area .uren-pagination-box.carlet-color > li.active > a {
  color: #e52e06;
}

.uren-paginatoin-area .uren-pagination-box.carlet-color > li > a {
  border-right: 1px solid #e52e06;
}

.uren-paginatoin-area .uren-pagination-box.carlet-color > li > a:hover {
  color: #e52e06 !important;
}

.uren-paginatoin-area .uren-pagination-box.primary-color {
  border: 2px solid #ffc400;
}

.uren-paginatoin-area .uren-pagination-box.primary-color > li:last-child > a {
  border-right: 0;
}

.uren-paginatoin-area .uren-pagination-box.primary-color > li.active > a {
  color: #ffc400;
}

.uren-paginatoin-area .uren-pagination-box.primary-color > li > a {
  border-right: 1px solid #ffc400;
}

.uren-paginatoin-area .uren-pagination-box.primary-color > li > a:hover {
  color: #ffc400 !important;
}

/* ---Uren's ScrollUp--- */
#scrollUp {
  background-color: #e52e06;
  color: #ffffff !important;
  text-transform: uppercase;
  right: 15px;
  bottom: 15px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  overflow: hidden;
  border-radius: 100%;
  z-index: 1000 !important;
}

#scrollUp:hover {
  background-color: #ffc400;
}

#scrollUp:hover > i {
  animation: alisSlideInUp 1s infinite;
}

/* ---Uren's Tooltip--- */
.tooltip {
  z-index: 10;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0 8px;
  height: 30px;
  line-height: 27px;
  color: #fff;
  text-align: center;
  border-radius: .25rem;
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  content: "";
  border-width: 5px 5px 0;
}

/* ---Uren's Quantity--- */
.quantity .cart-plus-minus {
  position: relative;
  width: 76px;
  text-align: left;
}

.quantity .cart-plus-minus > .cart-plus-minus-box {
  border: 1px solid #e5e5e5;
  height: 46px;
  text-align: center;
  width: 48px;
  background: #ffffff;
}

.quantity .cart-plus-minus > .qtybutton {
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  cursor: pointer;
  height: 23px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  width: 28px;
  line-height: 21px;
}

.quantity .cart-plus-minus > .qtybutton:hover {
  background: #ebebeb;
}

.quantity .cart-plus-minus > .qtybutton.dec {
  bottom: 0;
  right: 0;
}

.quantity .cart-plus-minus > .qtybutton.inc {
  border-bottom: none;
  top: 0;
  right: 0;
}

/* ---Uren's  Nice Select Customization--- */
.myniceselect.nice-select {
  padding: 0;
  margin: 0;
  height: auto;
  line-height: auto;
}

.myniceselect.nice-select span {
  height: 30px;
  line-height: 30px;
  display: inline-block;
  padding: 0 60px;
}

.myniceselect.nice-select .list {
  width: 100%;
}

/* ---Base Transition--- */
a,
button, .global-overlay, .quantity .cart-plus-minus > .qtybutton, .price-filter .ui-slider-handle, .img-hover_effect, .img-hover-effect_area .single-product, .img-hover-effect_area-2, .popup_wrapper, .popup_wrapper .test .popup_off, .popup_wrapper .subscribe_area .subscribe-form-group form.subscribe-form button, .uren-btn,
.uren-btn-2,
.uren-banner_btn,
.uren-btn-bondi_blue,
.uren-btn_fullwidth,
.uren-compare_btn,
.uren-filter_btn,
.uren-btn_dark,
.uren-btn_limerick, .main-slider .slick-dots li, .mobile-menu_wrapper .offcanvas-menu-inner,
.offcanvas-minicart_wrapper .offcanvas-menu-inner,
.offcanvas-search_wrapper .offcanvas-menu-inner, .mobile-menu_wrapper.open .offcanvas-menu-inner,
.offcanvas-minicart_wrapper.open .offcanvas-menu-inner,
.offcanvas-search_wrapper.open .offcanvas-menu-inner, .btn-close, .btn-close > i, .offcanvas-navigation .mobile-menu li > .menu-expand, .offcanvas-menu_wrapper .offcanvas-menu-inner, .offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_img, .category-menu .category-menu-list > ul > li.right-menu > a:after, .cat-mega-menu, .cat-dropdown, .product-slide_item .inner-slide .single-product, .product-slide_item .inner-slide .single-product .product-img > a .secondary-img, .product-slide_item .inner-slide .single-product .product-img .add-actions, .product-slide_item .inner-slide .single-product .product-img .add-actions > ul li > a, .product-slide_item .inner-slide .single-product .product-content, .special-product_slider .slide-item .inner-slide .single-product .product-img > a .secondary-img,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-img > a .secondary-img, .newsletter-form_wrap .subscribe-form .newsletter-btn, .modal-wrapper.modal, .modal-wrapper .modal-dialog .modal-content .modal-body .close, .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-img_slider-nav .slick-slide img, .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li, .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li .dropdown, .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li .dropdown > li, .slider-navigation_style-4 .slick-arrow, .header-top_area .main-menu_area > nav > ul > li .hm-dropdown, .header-top_area .main-menu_area > nav > ul > li > ul > li > a > i, .header-top_area .main-menu_area > nav > ul > li.megamenu-holder .hm-megamenu, .header-top_area .ht-menu > ul > li .ht-dropdown, .header-right_area > ul > li.minicart-wrap, .shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-img > a .secondary-img, .shop-product-wrap > [class*="col-"], .sp-area .sp-nav .sp-img_slider-nav .slick-slide img, .sp-area .sp-nav .sp-content .qty-btn_area > ul li > a, .sp-slider_area .sp-nav .sp-slider .product-slide_item .inner-slide .single-product .product-img > a .secondary-img, .sp-gallery_area .sp-nav .sp-gallery .lg-image, .uren-blog-details .uren-tag-line a, .myaccount-tab-content, .login-form .check-box input[type="checkbox"] + label:before, .login-form .check-box input[type="checkbox"] + label:after, .uren-login_btn,
.uren-register_btn, .table-content table td.uren-cart_btn a, .coupon-all .coupon input.button,
.coupon-all .coupon2 input.button, .cart-page-total a, .coupon-accordion span, .coupon-accordion .coupon-content .coupon-info p.form-row input[type="submit"], .order-button-payment input, .compare-table .table tbody tr, .contact-main-page .contact-form-content .contact-form .form-group .uren-contact-form_btn, .about-us-area .overview-content > h2:before, .error404-area .search-error-wrapper .uren-error_btn {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

/* ---Range Slider--- */
.price-filter {
  padding-top: 35px;
}

.price-filter .ui-widget-content {
  background-color: #e5e5e5;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  height: 10px;
  margin: 0 0 25px;
  width: 100%;
  border-top-left-radius: 25px;
  -moz-border-radius-topleft: 25px;
  border-top-right-radius: 25px;
  -moz-border-radius-topright: 25px;
  border-bottom-left-radius: 25px;
  -moz-border-radius-bottomleft: 25px;
  border-bottom-right-radius: 25px;
  -moz-border-radius-bottomright: 25px;
}

.price-filter .ui-slider-range {
  background: #ffc400;
  border: none;
  box-shadow: none;
  height: 100%;
}

.price-filter .ui-slider-handle {
  border: 2px solid #ffc400;
  display: block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 0;
  text-align: center;
  top: 50%;
  border-radius: 100%;
  box-shadow: 0px 0px 6.65px 0.35px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
}

.price-filter .ui-slider-handle:focus {
  border: 2px solid #ffc400;
  outline: 0;
}

.price-filter .ui-slider-handle:last-child {
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}

.price-filter .ui-slider-handle:hover {
  background-color: #ffc400;
}

.price-filter .price-slider-amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.price-filter .price-slider-amount .label-input label {
  color: #222;
  font-size: 14px;
  margin: 0;
  text-transform: capitalize;
}

.price-filter .price-slider-amount .label-input input {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  box-shadow: none;
  color: #222;
  font-size: 14px;
  height: inherit;
  padding-left: 5px;
  width: 99px;
}

/* ---Color List Area--- */
.color-list_area {
  border: 1px solid #e5e5e5;
  padding: 25px;
}

@media (max-width: 575px) {
  .color-list_area {
    padding: 15px;
  }
}

.color-list_area .color-list_heading {
  padding-bottom: 15px;
}

.color-list_area .color-list_heading > h4 {
  text-transform: uppercase;
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .color-list_area .color-list_heading > h4 {
    font-size: 14px;
  }
}

.color-list_area .sub-title {
  display: block;
  padding-bottom: 20px;
}

.color-list_area .color-list {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}

@media (max-width: 479px) {
  .color-list_area .color-list {
    padding-bottom: 25px;
    overflow: hidden;
  }
}

.color-list_area .color-list .single-color {
  border: 1px solid #e5e5e5;
  display: inline-block;
  margin-right: 5px;
  padding: 2px;
  width: 25px;
  height: 25px;
}

.color-list_area .color-list .single-color span {
  width: 100%;
  height: 100%;
}

.color-list_area .color-list .single-color span.bg-red_color {
  background-color: #ff0000;
  display: block;
}

.color-list_area .color-list .single-color span.burnt-orange_color {
  background-color: #ff832b;
  display: block;
}

.color-list_area .color-list .single-color span.brown_color {
  background-color: #a6311f;
  display: block;
}

.color-list_area .color-list .single-color span.raw-umber_color {
  background-color: #824900;
  display: block;
}

.color-list_area .color-list .single-color span.black_color {
  background-color: #000;
  display: block;
}

.color-list_area .color-list .single-color span.golden_color {
  background-color: #ffc400;
  display: block;
}

.color-list_area .color-list .single-color.active {
  border-color: #ffc400;
}

.color-list_area .color-list .single-color.active .color-text {
  color: #333333;
  display: block;
}

.color-list_area .color-list .color-text {
  position: absolute;
  right: -120px;
  width: auto !important;
  top: 0;
  display: none;
}

@media (max-width: 575px) {
  .color-list_area .color-list .color-text {
    right: -110px;
  }
}

@media (max-width: 479px) {
  .color-list_area .color-list .color-text {
    right: auto;
    left: 0;
    top: 30px;
  }
}

/* ---Uren's Image Hover Effect--- */
.img-hover_effect:hover {
  opacity: 0.7;
}

.img-hover-effect_area .slick-list {
  padding: 15px;
  margin: -15px;
  height: auto !important;
}

.img-hover-effect_area .single-product:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.img-hover-effect_area-2:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* ---Uren's Newsletters Popup--- */
.popup_wrapper {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
  height: 100%;
  opacity: 0;
  position: fixed;
  visibility: hidden;
  width: 100%;
  z-index: 9999999;
}

@media (max-width: 991px) {
  .popup_wrapper {
    display: none;
  }
}

.popup_wrapper .test {
  background: #ffffff;
  background-image: url("../images/newsletters/bg-1.jpg");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: 0;
  height: 425px;
  left: 0;
  margin: auto;
  max-width: 970px;
  padding: 50px 60px 50px;
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 1500px) {
  .popup_wrapper .test {
    max-width: 810px;
  }
}

.popup_wrapper .test .popup_off {
  background: #222222;
  color: #ffffff;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  cursor: pointer;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}

.popup_wrapper .test .popup_off:hover {
  background-color: #ffc400;
}

.popup_wrapper .subscribe_area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.popup_wrapper .subscribe_area > h2 {
  color: #333333;
  font-weight: 600;
  font-size: 30px;
  text-transform: capitalize;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.popup_wrapper .subscribe_area > p {
  margin: 0;
  max-width: 580px;
  padding-bottom: 25px;
  color: #333333;
  font-size: 16px;
}

.popup_wrapper .subscribe_area .subscribe-form-group {
  display: flex;
  justify-content: center;
}

.popup_wrapper .subscribe_area .subscribe-form-group form.subscribe-form {
  position: relative;
}

.popup_wrapper .subscribe_area .subscribe-form-group form.subscribe-form input {
  background: #ffffff;
  border: 1px solid #e5e5e5 !important;
  color: #333333;
  border: 0 none;
  height: 50px;
  padding: 0 150px 0 20px;
  width: 520px;
}

.popup_wrapper .subscribe_area .subscribe-form-group form.subscribe-form input::placeholder {
  color: #333333;
}

.popup_wrapper .subscribe_area .subscribe-form-group form.subscribe-form button {
  font-weight: 700;
  background: #ffc400;
  color: #333333;
  top: 0;
  right: 0;
  position: absolute;
  width: 130px;
  height: 50px;
  line-height: 50px;
  border: 0;
  display: block;
  text-transform: uppercase;
}

.popup_wrapper .subscribe_area .subscribe-form-group form.subscribe-form button:hover {
  background-color: #e52e06;
  color: #ffffff !important;
}

.popup_wrapper .subscribe_area .subscribe-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}

.popup_wrapper .subscribe_area .subscribe-bottom input {
  margin-right: 5px;
}

.popup_wrapper .subscribe_area .subscribe-bottom label {
  margin-bottom: 0;
  color: #333333;
}

/*----------------------------------------*/
/*  04 - Utilities - Color
/*----------------------------------------*/
/* ---Backgroud Color | Only For HTML Markup---*/
.bg--white_smoke {
  background-color: #f4f4f4;
}

.bg--white {
  background-color: #ffffff;
}

.bg--night_rider {
  background-color: #999999;
}

.bg--silver {
  background-color: #bababa;
}

.bg--nero {
  background-color: #222222;
}

.bg--black {
  background-color: #000;
}

.bg--sapphire {
  background-color: #0c2a5c;
}

.bg--primary {
  background-color: #ffc400;
}

/* ---Text Color | Only For HTML Markup---*/
.primary-text_color {
  color: #ffc400 !important;
}

.carlet-text_color {
  color: #e52e06 !important;
}

/* ---Activation Color--- */
.header-main_area .main-nav > ul > li.active > a,
.header-main_area-2 .main-nav > ul > li.active > a {
  color: #ffc400 !important;
}

.header-main_area-3 .main-nav > ul > li.active > a {
  color: #e52e06 !important;
}

/*----------------------------------------*/
/*  10 - Components - Button
/*----------------------------------------*/
/* --Uren's Search Button-- */
.uren-search_btn {
  background: transparent;
  border: 0;
  position: absolute;
  right: 10px;
  top: 15px;
  color: #888888;
}

.uren-search_btn:hover {
  color: #ffc400;
}

/* ---Uren's Button Position--- */
.uren-btn-ps_center {
  display: flex;
  justify-content: center;
}

.uren-btn-ps_center.uren-btn-ps_left {
  justify-content: flex-start;
}

.uren-btn-ps_center.uren-btn-ps_right {
  justify-content: flex-end;
}

/* --Uren's Button-- */
.uren-btn,
.uren-btn-2,
.uren-banner_btn,
.uren-btn-bondi_blue,
.uren-btn_fullwidth,
.uren-compare_btn,
.uren-filter_btn,
.uren-btn_dark,
.uren-btn_limerick {
  background-color: #ffc400;
  color: #333333;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0);
  width: 175px;
  height: 45px;
  line-height: 45px;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .uren-btn,
  .uren-btn-2,
  .uren-banner_btn,
  .uren-btn-bondi_blue,
  .uren-btn_fullwidth,
  .uren-compare_btn,
  .uren-filter_btn,
  .uren-btn_dark,
  .uren-btn_limerick {
    width: 140px;
    height: 30px;
    line-height: 29px;
    font-size: 14px;
  }
}

.uren-btn:hover,
.uren-btn-2:hover,
.uren-banner_btn:hover,
.uren-btn-bondi_blue:hover,
.uren-btn_fullwidth:hover,
.uren-compare_btn:hover,
.uren-filter_btn:hover,
.uren-btn_dark:hover,
.uren-btn_limerick:hover {
  background-color: #e52e06;
  color: #ffffff !important;
}

.uren-btn.uren-btn-2,
.uren-btn-2.uren-btn-2,
.uren-banner_btn.uren-btn-2,
.uren-btn-bondi_blue.uren-btn-2,
.uren-btn_fullwidth.uren-btn-2,
.uren-compare_btn.uren-btn-2,
.uren-filter_btn.uren-btn-2,
.uren-btn_dark.uren-btn-2,
.uren-btn_limerick.uren-btn-2 {
  background-color: #e52e06;
  color: #ffffff !important;
}

.uren-btn.uren-btn-2:hover,
.uren-btn-2.uren-btn-2:hover,
.uren-banner_btn.uren-btn-2:hover,
.uren-btn-bondi_blue.uren-btn-2:hover,
.uren-btn_fullwidth.uren-btn-2:hover,
.uren-compare_btn.uren-btn-2:hover,
.uren-filter_btn.uren-btn-2:hover,
.uren-btn_dark.uren-btn-2:hover,
.uren-btn_limerick.uren-btn-2:hover {
  background-color: #ffc400;
}

/* ---Uren's Button With Color Variation--- */
.uren-btn-bondi_blue {
  background-color: #ffc400;
  color: #ffffff;
}

.uren-btn-bondi_blue:hover {
  background-color: #999999;
  color: #ffffff !important;
}

.uren-btn_dark {
  background: #999999;
  color: #ffffff;
  width: 150px;
  height: 40px;
  line-height: 38px;
  font-weight: 600;
  border: 0;
}

.uren-btn_limerick {
  background-color: #ffc400;
  color: #ffffff;
  width: 120px;
  height: 40px;
  line-height: 40px;
}

.uren-btn_limerick:hover {
  background-color: #333333;
}

/* ---Uren's Button With Various Sizes--- */
.uren-btn_fullwidth {
  background-color: #666666;
  width: 100%;
  color: #ffffff;
  text-transform: uppercase;
}

.uren-btn_sm {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  font-weight: 400;
}

/* ---Uren's Other Button--- */
.uren-banner_btn {
  box-shadow: 0px 1px 6.79px 0.21px rgba(0, 0, 0, 0.13);
  height: 40px;
  line-height: 40px;
}

.uren-compare_btn {
  background: #ffc400;
  color: #ffffff;
  height: 40px;
  line-height: 43px;
  font-size: 14px;
}

.uren-compare_btn:hover {
  background-color: #999999;
}

.fb-filter-btn_area {
  padding-top: 30px;
}

.fb-filter-btn_area .uren-filter_btn {
  background-color: #999999;
  color: #ffffff;
  display: block;
  width: 120px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0;
}

.fb-filter-btn_area .uren-filter_btn:before {
  content: "\f00d";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  padding-right: 10px;
}

.fb-filter-btn_area .uren-filter_btn:hover {
  background-color: #ffc400;
}

/*----------------------------------------*/
/*  11 - Components - Slider
/*----------------------------------------*/
/* --Slider Area One--- */
.uren-slider_area .main-slider .slider-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15%;
}

.uren-slider_area .main-slider .slider-content > span {
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  padding-bottom: 25px;
  font-size: 24px;
  display: block;
}

@media (max-width: 767px) {
  .uren-slider_area .main-slider .slider-content > span {
    font-size: 15px;
    padding-bottom: 15px;
  }
}

.uren-slider_area .main-slider .slider-content > h3 {
  text-transform: uppercase;
  color: #ffffff;
  position: relative;
  font-size: 70px;
  padding-bottom: 30px;
  margin-bottom: 35px;
}

@media (max-width: 767px) {
  .uren-slider_area .main-slider .slider-content > h3 {
    font-size: 25px;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}

.uren-slider_area .main-slider .slider-content > h3:before {
  background-color: #ffc400;
  position: absolute;
  content: "";
  height: 3px;
  width: 70px;
  bottom: 0;
}

.uren-slider_area .main-slider .slider-content > h4 {
  font-weight: 400;
  color: #ffffff;
  font-size: 28px;
  margin-bottom: 0;
  padding-bottom: 25px;
}

@media (max-width: 767px) {
  .uren-slider_area .main-slider .slider-content > h4 {
    padding-bottom: 20px;
    font-size: 20px;
  }
}

.uren-slider_area .main-slider .slider-content > h4 > span {
  color: #ffc400;
}

@media (max-width: 767px) {
  .uren-slider_area .main-slider .slick-arrow {
    display: none !important;
  }
}

.uren-slider_area.uren-slider_area-2 {
  padding-top: 80px;
}

.uren-slider_area.uren-slider_area-2 .main-slider .slider-content {
  left: 6.2%;
}

.uren-slider_area.uren-slider_area-2 .banner-wrap [class*="col"]:not(:last-child) .banner-item {
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .uren-slider_area.uren-slider_area-2 .banner-wrap [class*="col"]:not(:last-child) .banner-item {
    margin-bottom: 0;
  }
}

.uren-slider_area.uren-slider_area-3 .main-slider .slider-content {
  text-align: center;
  left: 6.2%;
}

@media (max-width: 767px) {
  .uren-slider_area.uren-slider_area-3 .main-slider .slider-content {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.uren-slider_area.uren-slider_area-3 .main-slider .slider-content > h3 {
  color: #222222;
  font-size: 48px;
}

@media (max-width: 767px) {
  .uren-slider_area.uren-slider_area-3 .main-slider .slider-content > h3 {
    font-size: 32px;
  }
}

.uren-slider_area.uren-slider_area-3 .main-slider .slider-content > h3:before {
  background-color: #e52e06;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .uren-slider_area.uren-slider_area-3 .main-slider .slider-content > h3:before {
    left: auto;
    transform: none;
  }
}

.uren-slider_area.uren-slider_area-3 .main-slider .slider-content > p {
  color: #222222;
  margin-bottom: 0;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .uren-slider_area.uren-slider_area-3 .main-slider .slider-content > p {
    max-width: 70%;
  }
}

@media (max-width: 479px) {
  .uren-slider_area.uren-slider_area-3 .main-slider .slider-content > p {
    max-width: 100%;
  }
}

/* --Slider Background Image-- */
.bg-1,
.bg-2 {
  background-image: url("../images/slider/1.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 610px;
}

@media (max-width: 767px) {
  .bg-1,
  .bg-2 {
    min-height: 380px;
  }
}

.bg-2 {
  background-image: url("../images/slider/2.jpg");
}

.bg-3,
.bg-4 {
  background-image: url("../images/slider/3.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 550px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bg-3,
  .bg-4 {
    min-height: 480px;
  }
}

@media (max-width: 479px) {
  .bg-3,
  .bg-4 {
    min-height: 380px;
  }
}

.bg-4 {
  background-image: url("../images/slider/4.jpg");
}

.bg-5,
.bg-6 {
  background-image: url("../images/slider/5.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 610px;
}

@media (max-width: 767px) {
  .bg-5,
  .bg-6 {
    min-height: 380px;
  }
}

.bg-6 {
  background-image: url("../images/slider/6.jpg");
}

/* ---Slider Dots--- */
.main-slider .slick-dots {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 30px;
  z-index: 8;
}

.main-slider .slick-dots li {
  display: inline-block;
  margin: 0 3px;
  cursor: pointer;
  font-size: 0;
  width: 15px;
  height: 15px;
  background: transparent;
  border: 2px solid #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.main-slider .slick-dots li button {
  border: none;
}

.main-slider .slick-dots li.slick-active {
  background-color: #ffffff;
}

.main-slider .slick-dots li:hover {
  background-color: #ffffff;
}

/*----------------------------------------*/
/*  12 - Components - Animation
/*----------------------------------------*/
/* Uren's Animation Style One */
.slick-active .animation-style-01 .slider-content > span {
  display: block;
  -webkit-animation: 1200ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 1200ms ease-in-out 0s normal none 1 running zoomInUp;
}

.slick-active .animation-style-01 .slider-content > h2 {
  -webkit-animation: 1400ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 1400ms ease-in-out 0s normal none 1 running zoomInUp;
}

.slick-active .animation-style-01 .slider-content > h3 {
  -webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
}

.slick-active .animation-style-01 .slider-content > h4 {
  -webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
}

.slick-active .animation-style-01 .slider-content > h5 {
  -webkit-animation: 1800ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 1800ms ease-in-out 0s normal none 1 running zoomInUp;
}

.slick-active .animation-style-01 .slider-content > p {
  -webkit-animation: 1800ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 1800ms ease-in-out 0s normal none 1 running zoomInUp;
}

.slick-active .animation-style-01 .slider-content > .slide-btn {
  -webkit-animation: 2000ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 2000ms ease-in-out 0s normal none 1 running zoomInUp;
}

.slick-active .animation-style-02 .slider-content > span {
  display: block;
  -webkit-animation: 1200ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 1200ms ease-in-out 0s normal none 1 running zoomInRight;
}

.slick-active .animation-style-02 .slider-content > h2 {
  -webkit-animation: 1400ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 1400ms ease-in-out 0s normal none 1 running zoomInRight;
}

.slick-active .animation-style-02 .slider-content > h3 {
  -webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 1600ms ease-in-out 0s normal none 1 running zoomInRight;
}

.slick-active .animation-style-02 .slider-content > h4 {
  -webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 1600ms ease-in-out 0s normal none 1 running zoomInRight;
}

.slick-active .animation-style-02 .slider-content > h5 {
  -webkit-animation: 1800ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 1800ms ease-in-out 0s normal none 1 running zoomInRight;
}

.slick-active .animation-style-02 .slider-content > p {
  -webkit-animation: 1800ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 1800ms ease-in-out 0s normal none 1 running zoomInUp;
}

.slick-active .animation-style-02 .slider-content > .slide-btn {
  -webkit-animation: 2000ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 2000ms ease-in-out 0s normal none 1 running zoomInRight;
}

/*Top Up Keyframes*/
@-webkit-keyframes alisSlideInUp {
  0% {
    -webkit-transform: translateY(250px);
    transform: translateY(250px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes alisSlideInUp {
  0% {
    -webkit-transform: translateY(250px);
    transform: translateY(250px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 4s linear infinite alternate both;
  animation: slide-in-right 4s linear infinite alternate both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-1-7 17:0:13
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(340px);
    transform: translateX(340px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(340px);
    transform: translateX(340px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

/*----------------------------------------*/
/*  13 - Components - Navigation
/*----------------------------------------*/
/* ---Mobile Menu Wrapper--- */
.mobile-menu_wrapper .offcanvas-menu-inner,
.offcanvas-minicart_wrapper .offcanvas-menu-inner,
.offcanvas-search_wrapper .offcanvas-menu-inner {
  position: fixed;
  top: 0;
  right: -285px;
  width: 285px;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
}

@media (max-width: 575px) {
  .mobile-menu_wrapper .offcanvas-menu-inner,
  .offcanvas-minicart_wrapper .offcanvas-menu-inner,
  .offcanvas-search_wrapper .offcanvas-menu-inner {
    width: 270px;
  }
}

.mobile-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_search,
.offcanvas-minicart_wrapper .offcanvas-menu-inner .offcanvas-inner_search,
.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-inner_search {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: calc(100% - 0px);
  padding: 10px;
  background-color: #e5e5e5;
}

.mobile-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_search .inner-searchbox,
.offcanvas-minicart_wrapper .offcanvas-menu-inner .offcanvas-inner_search .inner-searchbox,
.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-inner_search .inner-searchbox {
  position: relative;
}

.mobile-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_search .inner-searchbox > input,
.offcanvas-minicart_wrapper .offcanvas-menu-inner .offcanvas-inner_search .inner-searchbox > input,
.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-inner_search .inner-searchbox > input {
  background-color: #e5e5e5;
  border: 0;
  height: 40px;
  line-height: 40px;
  width: 100%;
  padding: 0 52px 0 15px;
}

.mobile-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_search .inner-searchbox > .search_btn,
.offcanvas-minicart_wrapper .offcanvas-menu-inner .offcanvas-inner_search .inner-searchbox > .search_btn,
.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-inner_search .inner-searchbox > .search_btn {
  background: transparent;
  color: #999999;
  position: absolute;
  top: 10px;
  right: 20px;
  border: 0;
  font-size: 24px;
}

@media (max-width: 991px) {
  .mobile-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_search .inner-searchbox > .search_btn:hover,
  .offcanvas-minicart_wrapper .offcanvas-menu-inner .offcanvas-inner_search .inner-searchbox > .search_btn:hover,
  .offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-inner_search .inner-searchbox > .search_btn:hover {
    color: #ffc400;
  }
}

.mobile-menu_wrapper.open .offcanvas-menu-inner,
.offcanvas-minicart_wrapper.open .offcanvas-menu-inner,
.offcanvas-search_wrapper.open .offcanvas-menu-inner {
  left: auto;
  right: 0;
  visibility: visible;
  padding: 105px 0 0;
  opacity: 1;
}

/* ---Offcanvas Close Button--- */
.btn-close {
  position: absolute;
  top: 0;
  left: -60px;
  background: #333333;
  width: 60px;
  height: 60px;
  line-height: 63px;
  text-align: center;
  color: #ffffff;
  z-index: 10;
  font-size: 24px;
}

@media (max-width: 479px) {
  .btn-close {
    left: -50px;
    width: 50px;
  }
}

.btn-close:hover > i {
  transform: rotate(90deg);
}

.btn-close > i {
  transform: rotate(0);
  display: block;
}

/* ---Offcanvas Navigation Area--- */
.offcanvas-navigation .mobile-menu {
  overflow-y: auto;
  min-height: 165px;
}

.offcanvas-navigation .mobile-menu > li {
  height: 100%;
}

.offcanvas-navigation .mobile-menu > li > a span {
  position: relative;
  font-weight: 600;
}

.offcanvas-navigation .mobile-menu li {
  position: relative;
}

.offcanvas-navigation .mobile-menu li > .menu-expand {
  position: absolute;
  right: 0;
  top: 2px;
  width: 50px;
  height: 100%;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  margin-right: 4px;
}

.offcanvas-navigation .mobile-menu li a {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  padding: 10px 20px;
}

.offcanvas-navigation .mobile-menu li .sub-menu {
  padding-left: 10px;
}

.offcanvas-navigation .mobile-menu li .sub-menu li a {
  text-transform: capitalize;
  font-size: 13px;
}

.offcanvas-navigation .mobile-menu li.menu-open > .menu-expand i:before {
  content: '\f123';
  color: #ffc400;
}

.offcanvas-navigation .mobile-menu li:hover > a {
  color: #ffc400;
}

.offcanvas-navigation .mobile-menu li:hover > span {
  color: #ffc400;
}

/* ---Header User Setting Area--- */
.user-setting_area {
  padding-top: 25px;
}

.user-setting_area > ul > li > a > span {
  text-transform: capitalize;
}

/* ---Offcanvas Menu Wrapper--- */
.offcanvas-menu_wrapper {
  text-align: center;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner {
  padding: 25px 30px 30px;
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  transform: scaleY(0);
  transform-origin: 0 0 0;
  width: 375px;
  height: 100%;
  background: #ffffff;
  z-index: 9999;
  visibility: hidden;
  min-height: 600px;
  overflow-y: auto;
  /* ---Offcanvas Component--- */
  /* ---Offcanvas Inner Social Link--- */
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .btn-close {
  top: auto;
  left: auto;
  width: auto;
  height: auto;
  line-height: 1;
  background: transparent;
  color: #999999;
  z-index: 10;
  font-size: 24px;
  position: relative;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .btn-close:hover {
  background: transparent;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component {
  text-align: left;
  padding-top: 20px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component.first-child {
  padding-top: 25px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component .offcanvas-component_title {
  font-size: 16px;
  font-weight: 900;
  display: block;
  padding-bottom: 15px;
  text-transform: uppercase;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component .offcanvas-component_menu > li {
  display: inline-block;
  position: relative;
  padding-right: 40px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component .offcanvas-component_menu > li:last-child {
  padding-right: 0;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component .offcanvas-component_menu > li:after {
  content: "";
  width: 18px;
  height: 1px;
  background: #e5e5e5;
  display: block;
  top: 50%;
  right: 10px;
  position: absolute;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component .offcanvas-component_menu > li:last-child:after {
  background: transparent;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner-social_link {
  position: absolute;
  bottom: 30px;
}

.offcanvas-menu_wrapper.open .offcanvas-menu-inner {
  top: 0;
  visibility: visible;
  transform: scaleY(1);
}

.offcanvas-menu_wrapper .offcanvas-inner_logo {
  padding: 25px 0;
}

.offcanvas-menu_wrapper .short-desc {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 25px;
}

.offcanvas-menu_wrapper .short-desc > p {
  margin-bottom: 0;
}

/* ---Offcanvas Minicart Area--- */
.offcanvas-minicart_wrapper .offcanvas-menu-inner {
  padding: 60px !important;
  width: 450px;
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 479px) {
  .offcanvas-minicart_wrapper .offcanvas-menu-inner {
    width: 100%;
    padding: 60px 15px !important;
  }
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .btn-close {
  background: transparent;
  color: #999999;
  top: 0;
  right: 0;
  left: auto;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-heading > h4 {
  margin-bottom: 0;
  padding-bottom: 25px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list {
  max-height: 310px;
  position: relative;
  overflow: auto;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li {
  padding-bottom: 30px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li:last-child {
  padding-bottom: 0;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product {
  display: flex;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product > a.product-item_remove {
  position: absolute;
  right: 15px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_img {
  border: 1px solid #e5e5e5;
  -ms-flex-preferred-size: 70px;
  -webkit-flex-basis: 70px;
  -moz-flex-basis: 70px;
  flex-basis: 70px;
  max-width: 70px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_img:hover {
  border-color: #ffc400;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content {
  -webkit-flex-basis: calc(100% - 70px);
  -moz-flex-basis: calc(100% - 70px);
  -ms-flex-preferred-size: calc(100% - 70px);
  flex-basis: calc(100% - 70px);
  max-width: calc(100% - 70px);
  padding-left: 20px;
  padding-right: 10px;
}

@media (max-width: 479px) {
  .offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content a.product-item_title {
    font-size: 14px;
  }
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content .product-item_quantity {
  display: block;
  padding-top: 10px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-item_total {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  padding: 25px 0;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area {
  padding-bottom: 15px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area .hiraola-btn {
  height: 60px;
  line-height: 60px;
  color: #ffffff !important;
}

/* ---Offcanvas Search Area--- */
.offcanvas-search_wrapper .offcanvas-menu-inner {
  background-color: #222222;
  opacity: 0.97;
  width: 100%;
  right: 0;
  transform: scale(0);
  visibility: visible;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .btn-close {
  background: transparent;
  color: #999999;
  top: 15px;
  right: 15px;
  left: auto;
  font-size: 45px;
  color: #ffffff;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search {
  background-color: #e5e5e5;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  width: calc(100% - 25%);
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox {
  position: relative;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > input {
  background-color: #333333;
  border: 0;
  height: 100px;
  line-height: 100px;
  width: 100%;
  padding: 0 100px 0 25px;
  color: #ffffff;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > input::placeholder {
  color: #ffffff;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > .search_btn {
  position: absolute;
  font-size: 48px;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  border: 0;
  background: transparent;
  color: #ffffff;
}

.offcanvas-search_wrapper.open .offcanvas-menu-inner {
  left: auto;
  right: 0;
  padding: 0;
  transform: scale(1);
  visibility: visible;
}

/* ---Category Menu--- */
.category-menu {
  position: relative;
}

.category-menu .category-heading {
  background: #ffc400;
  cursor: pointer;
  border-radius: 5px;
  height: 55px;
  display: flex;
  justify-content: space-evenly;
}

.category-menu .category-heading > h2 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  height: 55px;
  line-height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.category-menu .category-heading > h2:before {
  content: "\f394";
  font-family: 'Ionicons';
  position: absolute;
  top: 0;
  left: 20px;
  font-size: 40px;
}

.category-menu .category-heading > h2:after {
  content: "\f3d0";
  font-family: 'Ionicons';
  position: absolute;
  right: 20px;
  font-size: 25px;
}

.category-menu .category-heading > h2 > span {
  text-transform: uppercase;
  line-height: 14px;
  line-height: 18px;
}

.category-menu .category-heading > h2 > span:first-child {
  font-size: 14px;
}

.category-menu .category-menu-list {
  background: #ffffff;
  border: 2px solid #ffc400;
  border-radius: 5px;
  padding: 0 20px;
  position: absolute;
  left: 0;
  top: calc(100% - 1px);
  display: block;
  width: 100%;
  z-index: 99;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-menu .category-menu-list {
    padding: 0 15px;
  }
}

.category-menu .category-menu-list > ul > li {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
}

.category-menu .category-menu-list > ul > li > a {
  display: block;
  text-transform: capitalize;
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
}

.category-menu .category-menu-list > ul > li:hover .cat-mega-menu,
.category-menu .category-menu-list > ul > li:hover .cat-dropdown {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  visibility: visible;
}

.category-menu .category-menu-list > ul > li:hover > a {
  color: #ffc400;
}

.category-menu .category-menu-list > ul > li.right-menu > a:after {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  font-size: 12px;
  position: absolute;
  top: 15px;
  right: 0;
}

.category-menu .category-menu-list > ul > li.rx-child {
  display: none;
}

.category-menu .category-menu-list > ul > li.rx-parent {
  cursor: pointer;
}

.category-menu .category-menu-list > ul > li.rx-parent a.rx-show {
  display: none;
}

.category-menu .category-menu-list > ul > li.rx-parent.rx-change a.rx-default {
  display: none;
}

.category-menu .category-menu-list > ul > li.rx-parent.rx-change a.rx-show {
  display: block;
}

.cat-mega-menu {
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 109%;
  z-index: 999;
  width: 220px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  visibility: hidden;
  width: 640px;
  padding: 30px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .cat-mega-menu {
    position: static;
    z-index: 9;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    border: 0;
    width: 100%;
    transition: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cat-mega-menu {
    left: 108%;
  }
}

.cat-mega-menu > li.right-menu {
  width: 33.333%;
  float: left;
}

.cat-mega-menu > li.cat-mega-title > a {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 15px;
  display: block;
  line-height: 20px;
  position: relative;
}

.cat-mega-menu > li.cat-mega-title > a:hover {
  color: #ffffff;
}

.cat-mega-menu > li > ul > li > a {
  font-size: 14px;
  display: block;
  line-height: 30px;
  font-weight: 400;
  color: #666666;
  text-transform: capitalize;
}

.cat-mega-menu > li > ul > li > a:hover {
  color: #58d173;
}

.cat-mega-menu.cat-mega-menu-2 {
  width: 840px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cat-mega-menu.cat-mega-menu-2 {
    width: 675px;
  }
}

@media (max-width: 991px) {
  .cat-mega-menu.cat-mega-menu-2 {
    width: auto;
  }
}

.cat-mega-menu.cat-mega-menu-2 > li.right-menu {
  width: 25%;
}

@media (max-width: 991px) {
  .cat-mega-menu.cat-mega-menu-2 > li.right-menu {
    width: 100%;
  }
}

.cat-mega-menu.cat-mega-menu-3 {
  width: 440px;
}

@media (max-width: 991px) {
  .cat-mega-menu.cat-mega-menu-3 {
    width: auto;
  }
}

.cat-mega-menu.cat-mega-menu-3 > li.right-menu {
  width: 50%;
}

@media (max-width: 991px) {
  .cat-mega-menu.cat-mega-menu-3 > li.right-menu {
    width: 100%;
  }
}

/* ---Category Dropdown Menu--- */
.cat-dropdown {
  border: 1px solid #e5e5e5;
  position: absolute;
  top: 0;
  left: 110%;
  background: #ffffff;
  z-index: 999;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  visibility: hidden;
  width: 205px;
  padding: 0 30px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .cat-dropdown {
    position: static;
    z-index: 9;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    border: 0;
    width: 100%;
    transition: none;
    height: 138px;
    overflow-y: auto;
  }
}

.cat-dropdown > li > a {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 0;
  display: block;
}

.cat-dropdown.cat-dropdown-2 {
  width: 320px;
}

@media (max-width: 991px) {
  .cat-dropdown.cat-dropdown-2 {
    position: static;
    z-index: 9;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    border: 0;
    width: 100%;
    transition: none;
    height: 138px;
    overflow-y: auto;
  }
}

/* ---Category Menu In Mobile Device--- */
@media (max-width: 991px) {
  .category-menu-list > ul > li > ul > li > ul {
    height: 138px;
    overflow-y: auto;
  }
  .category-menu-list > ul > li.right-menu .cat-mega-menu > li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    padding: 0;
    position: relative;
  }
  .category-menu-list > ul > li.right-menu .cat-mega-menu > li.cat-mega-title > a {
    padding: 10px 25px 10px 35px;
    font-size: 13px;
    font-weight: normal;
    position: relative;
    margin: 0;
    display: block;
    text-transform: inherit;
  }
  .category-menu-list > ul > li.right-menu .cat-mega-menu > li.cat-mega-title > a:after {
    display: none;
  }
  .category-menu-list > ul > li.right-menu .cat-mega-menu > li.cat-mega-title:last-child > a {
    border-bottom: 0;
  }
  .category-menu-list > ul > li.right-menu .cat-mega-menu > li > ul > li > a {
    padding: 10px 25px 10px 35px;
    font-size: 13px;
    font-weight: normal;
    position: relative;
    margin: 0;
    display: block;
    text-transform: inherit;
    color: inherit;
  }
  .category-menu-list > ul > li.right-menu .cat-mega-menu > li > ul > li:last-child > a {
    border-bottom: 0;
  }
  .category-menu-list > ul > li.right-menu .cat-dropdown > li > a {
    padding: 10px 25px 10px 35px;
    font-size: 13px;
    font-weight: normal;
    position: relative;
    margin: 0;
    display: block;
    text-transform: inherit;
  }
  .category-menu-list > ul > li.right-menu .cat-dropdown > li:last-child > a {
    border-bottom: 0;
  }
  .category-menu-list > ul > li.right-menu > a:after {
    display: none;
  }
  .category-menu-list > ul > li i.menu-expand {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    height: 44px;
    width: 45px;
    line-height: 45px;
    z-index: 9;
    display: block;
    font-style: normal;
  }
  .category-menu-list > ul > li i.menu-expand:before {
    content: "\f489";
    font-family: "Ionicons";
    font-size: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out;
  }
  .category-menu-list > ul > li i.menu-expand.active:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

/*----------------------------------------*/
/*  14 - Components - Banner
/*----------------------------------------*/
.uren-banner_area {
  padding: 80px 0 0;
}

.uren-banner_area.bg--white_smoke {
  padding-bottom: 80px;
}

.uren-banner_area .banner-item {
  position: relative;
  cursor: pointer;
}

.uren-banner_area .banner-item .banner-img-1 {
  background-image: url("../images/banner/1-1.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 435px;
}

@media (max-width: 479px) {
  .uren-banner_area .banner-item .banner-img-1 {
    min-height: 320px;
  }
}

.uren-banner_area .banner-item .banner-img-1.banner-img-2 {
  background-image: url("../images/banner/1-2.jpg");
}

.uren-banner_area .banner-item .banner-content {
  position: absolute;
  top: 50%;
  left: 8.5%;
  transform: translateY(-50%);
}

.uren-banner_area .banner-item .banner-content > span.offer {
  text-transform: uppercase;
  color: #ffc400;
  font-size: 16px;
  display: block;
  padding-bottom: 15px;
}

@media (max-width: 1599px) {
  .uren-banner_area .banner-item .banner-content > span.offer {
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .uren-banner_area .banner-item .banner-content > span.offer {
    padding-bottom: 5px;
    font-size: 14px;
  }
}

.uren-banner_area .banner-item .banner-content > h4,
.uren-banner_area .banner-item .banner-content h3 {
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 0;
  font-size: 34px;
  text-transform: uppercase;
}

@media (max-width: 1599px) {
  .uren-banner_area .banner-item .banner-content > h4,
  .uren-banner_area .banner-item .banner-content h3 {
    font-size: 28px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .uren-banner_area .banner-item .banner-content > h4,
  .uren-banner_area .banner-item .banner-content h3 {
    font-size: 20px;
  }
}

.uren-banner_area .banner-item .banner-content > h3 {
  font-weight: 700;
  position: relative;
  padding-top: 5px;
  padding-bottom: 40px;
}

@media (max-width: 1599px) {
  .uren-banner_area .banner-item .banner-content > h3 {
    padding-bottom: 15px;
  }
}

.uren-banner_area .banner-item .banner-content > h3:before {
  background-color: #ffc400;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 70px;
  height: 2px;
}

.uren-banner_area .banner-item .banner-content > p {
  color: #ffffff;
  max-width: 55%;
  margin-bottom: 0;
  padding-top: 30px;
}

@media (max-width: 1599px) {
  .uren-banner_area .banner-item .banner-content > p {
    padding-top: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .uren-banner_area .banner-item .banner-content > p {
    max-width: 84%;
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .uren-banner_area .banner-item .banner-content > p {
    max-width: 84%;
    font-size: 13px;
  }
}

@media (max-width: 479px) {
  .uren-banner_area .banner-item .banner-content > p {
    max-width: 100%;
  }
}

.uren-banner_area .banner-item .banner-content > .uren-btn-ps_left {
  padding-top: 25px;
}

@media (max-width: 1599px) {
  .uren-banner_area .banner-item .banner-content > .uren-btn-ps_left {
    padding-top: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .uren-banner_area .banner-item .banner-content > .uren-btn-ps_left {
    padding-top: 10px;
  }
}

.uren-banner_area .banner-item .banner-content > .uren-btn-ps_left .uren-btn {
  width: 130px;
}

@media (max-width: 991px) {
  .uren-banner_area [class*="col-"]:not(:last-child) {
    margin-bottom: 30px;
  }
}

.uren-banner_area-3 .banner-item .banner-img {
  background-image: url("../images/banner/1-6.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 590px;
}

@media (max-width: 991px) {
  .uren-banner_area-3 .banner-item .banner-img {
    border: 1px solid #e5e5e5;
    min-height: 450px;
    background-position: left;
  }
}

@media (max-width: 479px) {
  .uren-banner_area-3 .banner-item .banner-img {
    min-height: 320px;
  }
}

.uren-banner_area-3 .banner-item .banner-content {
  text-align: center;
  left: auto;
}

@media (max-width: 1500px) {
  .uren-banner_area-3 .banner-item .banner-content {
    text-align: left;
  }
}

@media (max-width: 991px) {
  .uren-banner_area-3 .banner-item .banner-content {
    text-align: center;
  }
}

.uren-banner_area-3 .banner-item .banner-content span.contact-info {
  font-weight: 700;
  color: #e52e06;
  font-size: 24px;
}

@media (max-width: 1500px) {
  .uren-banner_area-3 .banner-item .banner-content span.contact-info {
    font-size: 16px;
  }
}

.uren-banner_area-3 .banner-item .banner-content > h4 {
  font-weight: 400;
  text-transform: uppercase;
  color: #333333;
  padding-top: 30px;
  font-size: 40px;
}

@media (max-width: 1500px) {
  .uren-banner_area-3 .banner-item .banner-content > h4 {
    padding-top: 10px;
    font-size: 25px;
  }
}

@media (max-width: 479px) {
  .uren-banner_area-3 .banner-item .banner-content > h4 {
    font-size: 20px;
  }
}

.uren-banner_area-3 .banner-item .banner-content > h3 {
  font-weight: 700;
  color: #333333;
  font-size: 60px;
  padding-bottom: 0;
}

@media (max-width: 1500px) {
  .uren-banner_area-3 .banner-item .banner-content > h3 {
    font-size: 30px;
  }
}

@media (max-width: 479px) {
  .uren-banner_area-3 .banner-item .banner-content > h3 {
    font-size: 25px;
  }
}

.uren-banner_area-3 .banner-item .banner-content > h3:before {
  display: none;
}

.uren-banner_area-3 .banner-item .banner-content > p {
  color: inherit;
  margin: 0 auto;
  padding-top: 5px;
}

@media (max-width: 1500px) {
  .uren-banner_area-3 .banner-item .banner-content > p {
    margin: 0;
    max-width: 65%;
  }
}

@media (max-width: 991px) {
  .uren-banner_area-3 .banner-item .banner-content > p {
    margin: 0 auto;
  }
}

@media (max-width: 479px) {
  .uren-banner_area-3 .banner-item .banner-content > p {
    max-width: 100%;
  }
}

.uren-banner_area-3 .banner-item .banner-content > a.read-more {
  font-weight: 700;
  text-transform: uppercase;
  color: #ffc400;
  padding-top: 45px;
  display: block;
}

@media (max-width: 1500px) {
  .uren-banner_area-3 .banner-item .banner-content > a.read-more {
    padding-top: 15px;
  }
}

.uren-banner_area-3 .banner-item .banner-content > a.read-more:hover {
  color: #e52e06 !important;
}

/*----------------------------------------*/
/*  15 - Components - Product
/*----------------------------------------*/
/* ---Product Slide Item--- */
.product-slide_item .inner-slide {
  border-right: 1px solid #e5e5e5;
  height: auto !important;
}

.product-slide_item .inner-slide .single-product {
  position: relative;
  padding: 15px;
}

.product-slide_item .inner-slide .single-product .product-img {
  position: relative;
}

.product-slide_item .inner-slide .single-product .product-img > a {
  display: block;
}

.product-slide_item .inner-slide .single-product .product-img > a img {
  width: 100%;
}

.product-slide_item .inner-slide .single-product .product-img > a .secondary-img {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}

.product-slide_item .inner-slide .single-product .product-img:hover .secondary-img {
  opacity: 1;
}

.product-slide_item .inner-slide .single-product .product-img .add-actions {
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  opacity: 0;
  z-index: 9;
}

.product-slide_item .inner-slide .single-product .product-img .add-actions > ul li {
  position: relative;
  margin-bottom: 5px;
  display: inline-block;
}

.product-slide_item .inner-slide .single-product .product-img .add-actions > ul li > a {
  border-radius: 5px;
  border: 1px solid #ffc400;
  color: #333333;
  display: block;
  width: 47px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  transform: scale(0);
}

.product-slide_item .inner-slide .single-product .product-img .add-actions > ul li > a > i {
  font-size: 18px;
}

.product-slide_item .inner-slide .single-product .product-img .add-actions > ul li > a:hover {
  background-color: #ffc400;
  color: #333333 !important;
}

.product-slide_item .inner-slide .single-product:hover > .product-content {
  opacity: 0;
}

.product-slide_item .inner-slide .single-product:hover > .product-img .add-actions {
  opacity: 1;
}

.product-slide_item .inner-slide .single-product:hover > .product-img .add-actions > ul > li > a {
  transform: scale(1);
}

.product-slide_item .inner-slide .single-product .product-content {
  text-align: center;
}

.product-slide_item .inner-slide .single-product .product-content .product-desc_info h6 {
  font-weight: 400;
  padding-bottom: 5px;
  margin-bottom: 0;
  display: block;
  font-size: 15px;
}

.product-slide_item .inner-slide .single-product .product-content .product-desc_info .rating-box {
  padding-bottom: 10px;
}

.product-slide_item .inner-slide .single-product .product-content .product-desc_info .rating-box > ul > li {
  display: inline-block;
}

.product-slide_item .inner-slide .single-product .product-content .product-desc_info .price-box {
  display: inline-block;
}

.product-slide_item .inner-slide .single-product .product-content .product-desc_info .price-box .new-price {
  font-size: 16px;
  color: #0886cf;
}

.product-slide_item .inner-slide .single-product .product-content .product-desc_info .price-box .new-price.new-price-2 {
  color: #e52e06;
  font-weight: 700;
}

.product-slide_item .inner-slide .single-product .product-content .product-desc_info .price-box .old-price {
  color: #bababa;
  text-decoration: line-through;
  font-size: 14px;
  margin-left: 10px;
}

.uren-product_area {
  padding: 75px 0 0;
}

.uren-product_area .section-title_area {
  margin-bottom: -20px;
}

/* ---Uren's Product Slider--- */
.product-slider,
.best-selling_slider {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
}

/* ---Uren's Special Product--- */
.special-product_area {
  padding: 75px 0 0;
}

.special-product_slider,
.special-product_slider-2 {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-right: 0;
}

.special-product_slider .slide-item .inner-slide .single-product,
.special-product_slider-2 .slide-item .inner-slide .single-product {
  display: flex;
  padding: 30px;
  border-right: 1px solid #e5e5e5;
}

@media (max-width: 1599px) {
  .special-product_slider .slide-item .inner-slide .single-product,
  .special-product_slider-2 .slide-item .inner-slide .single-product {
    padding: 15px;
  }
}

@media (max-width: 1199px) {
  .special-product_slider .slide-item .inner-slide .single-product,
  .special-product_slider-2 .slide-item .inner-slide .single-product {
    flex-direction: column;
    height: auto;
    padding: 30px;
  }
}

@media (max-width: 479px) {
  .special-product_slider .slide-item .inner-slide .single-product,
  .special-product_slider-2 .slide-item .inner-slide .single-product {
    padding: 15px;
  }
}

.special-product_slider .slide-item .inner-slide .single-product .product-img,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-img {
  position: relative;
  z-index: 1;
  width: 45%;
}

@media (max-width: 1199px) {
  .special-product_slider .slide-item .inner-slide .single-product .product-img,
  .special-product_slider-2 .slide-item .inner-slide .single-product .product-img {
    width: 100%;
  }
}

.special-product_slider .slide-item .inner-slide .single-product .product-img > a,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-img > a {
  display: block;
}

.special-product_slider .slide-item .inner-slide .single-product .product-img > a img,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-img > a img {
  width: 100%;
}

.special-product_slider .slide-item .inner-slide .single-product .product-img > a .secondary-img,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-img > a .secondary-img {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}

.special-product_slider .slide-item .inner-slide .single-product .product-img:hover .secondary-img,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-img:hover .secondary-img {
  opacity: 1;
}

.special-product_slider .slide-item .inner-slide .single-product .product-content,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content {
  width: 55%;
  padding-left: 15px;
}

@media (max-width: 1199px) {
  .special-product_slider .slide-item .inner-slide .single-product .product-content,
  .special-product_slider-2 .slide-item .inner-slide .single-product .product-content {
    padding-left: 0;
    width: 100%;
  }
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .uren-countdown_area span.product-offer,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .uren-countdown_area span.product-offer {
  color: #e52e06;
  padding-bottom: 10px;
  display: block;
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .rating-box,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .rating-box {
  padding-top: 25px;
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .rating-box > ul > li,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .rating-box > ul > li {
  display: inline-block;
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info > h6.product-name,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info > h6.product-name {
  font-weight: 400;
  margin-bottom: 0;
  padding-top: 15px;
  font-size: 24px;
}

@media (max-width: 1500px) {
  .special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info > h6.product-name,
  .special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info > h6.product-name {
    font-size: 17px;
  }
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info > h6.product-name > a,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info > h6.product-name > a {
  color: #222222;
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .price-box,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .price-box {
  display: inline-block;
  padding-top: 20px;
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .price-box .new-price,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .price-box .new-price {
  font-size: 16px;
  color: #0886cf;
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .price-box .new-price.new-price-2,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .price-box .new-price.new-price-2 {
  color: #e52e06;
  font-weight: 700;
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .price-box .old-price,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .price-box .old-price {
  color: #bababa;
  text-decoration: line-through;
  font-size: 14px;
  margin-left: 10px;
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .price-box .new-price,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .price-box .new-price {
  font-size: 24px;
}

@media (max-width: 1500px) {
  .special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .price-box .new-price,
  .special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .price-box .new-price {
    font-size: 16px;
  }
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions {
  padding-top: 25px;
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li {
  display: inline-block;
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a {
  border: 1px solid #e5e5e5;
  display: block;
  width: 47px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a,
  .special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a:hover,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a:hover {
  background-color: #ffc400;
  color: #ffffff !important;
  border-color: #ffc400;
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a.uren-add_cart,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a.uren-add_cart {
  font-weight: 700;
  background: #ffc400;
  color: #222222;
  width: 170px;
  font-size: 16px;
  text-transform: uppercase;
}

@media (max-width: 1599px) {
  .special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a.uren-add_cart,
  .special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a.uren-add_cart {
    width: 132px;
    font-size: 13px;
  }
}

@media (max-width: 479px) {
  .special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a.uren-add_cart,
  .special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a.uren-add_cart {
    width: 115px;
    text-transform: capitalize;
  }
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a.uren-add_cart:hover,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a.uren-add_cart:hover {
  background-color: #e52e06;
  color: #ffffff !important;
  border-color: #e52e06;
}

.special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a.uren-add_cart > i,
.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a.uren-add_cart > i {
  margin-right: 15px;
}

@media (max-width: 1599px) {
  .special-product_slider .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a.uren-add_cart > i,
  .special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .add-actions ul > li > a.uren-add_cart > i {
    margin-right: 10px;
  }
}

.special-product_slider.special-product_slider-2,
.special-product_slider-2.special-product_slider-2 {
  background-color: #ffffff;
  border: 0;
  overflow: hidden;
}

@media (max-width: 1199px) {
  .special-product_slider.special-product_slider-2,
  .special-product_slider-2.special-product_slider-2 {
    margin-bottom: 30px;
    overflow: hidden;
  }
}

.special-product_slider.special-product_slider-2 .slide-item .inner-slide .single-product,
.special-product_slider-2.special-product_slider-2 .slide-item .inner-slide .single-product {
  flex-direction: column;
  border-right: 0;
}

.special-product_slider.special-product_slider-2 .slide-item .inner-slide .single-product .product-img,
.special-product_slider-2.special-product_slider-2 .slide-item .inner-slide .single-product .product-img {
  width: 100%;
}

.special-product_slider.special-product_slider-2 .slide-item .inner-slide .single-product .product-content,
.special-product_slider-2.special-product_slider-2 .slide-item .inner-slide .single-product .product-content {
  width: 100%;
  padding-left: 0;
}

.special-product_slider.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .product-name,
.special-product_slider-2.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .product-name {
  font-size: 20px;
}

.special-product_slider.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .uren-countdown .count,
.special-product_slider-2.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .uren-countdown .count {
  width: 60px;
  margin-left: 10px;
}

@media (max-width: 1500px) {
  .special-product_slider.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .uren-countdown .count,
  .special-product_slider-2.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .uren-countdown .count {
    width: 50px;
    padding: 5px 0;
  }
}

.special-product_slider.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .uren-countdown .count:first-child,
.special-product_slider-2.special-product_slider-2 .slide-item .inner-slide .single-product .product-content .product-desc_info .uren-countdown .count:first-child {
  margin-left: 0;
}

/* ---Best Selling Product--- */
.best-selling-product_area {
  padding-top: 75px;
}

.best-selling-product_area .best-selling_banner {
  background-image: url("../images/banner/2-6.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 100%;
}

/* ---Multiple Sction area--- */
.multiple-section_area {
  padding: 75px 0 0;
}

.multiple-section_area .section-title_area > h3 {
  font-size: 23px;
}

.special-product_wrap .special-product_slider-2 .slick-list {
  margin: 0 -15px -15px;
}

.list-product_wrap .list-product_slider .slick-list {
  margin: 0 -15px -15px;
}

.list-product_slider {
  overflow: hidden;
}

@media (max-width: 1199px) {
  .list-product_slider.section-space_mn-30 {
    margin-bottom: 30px;
    overflow: hidden;
  }
}

.list-product_slider .slide-item .inner-slide .single-product {
  display: flex;
  padding: 13.7px 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .list-product_slider .slide-item .inner-slide .single-product {
    padding: 18px 15px;
  }
}

@media (max-width: 479px) {
  .list-product_slider .slide-item .inner-slide .single-product {
    display: block;
  }
}

.list-product_slider .slide-item .inner-slide .single-product .product-img {
  width: 40%;
}

@media (max-width: 479px) {
  .list-product_slider .slide-item .inner-slide .single-product .product-img {
    width: 100%;
  }
}

.list-product_slider .slide-item .inner-slide .single-product .product-content {
  width: 60%;
  padding-left: 15px;
}

@media (max-width: 479px) {
  .list-product_slider .slide-item .inner-slide .single-product .product-content {
    width: 100%;
    padding-left: 0;
  }
}

.list-product_slider .slide-item .inner-slide .single-product .product-content .rating-box {
  padding-bottom: 10px;
}

.list-product_slider .slide-item .inner-slide .single-product .product-content .rating-box > ul > li {
  display: inline-block;
}

.list-product_slider .slide-item .inner-slide .single-product .product-content > h3.product-name {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0;
  padding-bottom: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .list-product_slider .slide-item .inner-slide .single-product .product-content > h3.product-name {
    font-size: 15px;
  }
}

.list-product_slider .slide-item .inner-slide .single-product .product-content > .price-box .new-price {
  font-size: 16px;
  color: #0886cf;
}

.list-product_slider .slide-item .inner-slide .single-product .product-content > .price-box .new-price.new-price-2 {
  color: #e52e06;
  font-weight: 700;
}

/* ---Uren's Countdown--- */
.countdown-wrap .countdown.item-4 .countdown__item {
  border: 1px solid #e5e5e5;
  display: inline-block;
  position: relative;
  width: 70px;
  padding: 10px 0;
  text-align: center;
  margin-left: 15px;
}

.countdown-wrap .countdown.item-4 .countdown__item:first-child {
  margin-left: 0;
}

@media (max-width: 1599px) {
  .countdown-wrap .countdown.item-4 .countdown__item {
    width: 50px;
    margin-left: 5px;
    padding: 5px 0;
  }
}

@media (max-width: 479px) {
  .countdown-wrap .countdown.item-4 .countdown__item {
    width: 45px;
  }
}

.countdown-wrap .countdown.item-4 .countdown__item span.countdown__time {
  display: block;
  font-weight: 700;
  color: #333333;
  font-size: 24px;
}

@media (max-width: 1599px) {
  .countdown-wrap .countdown.item-4 .countdown__item span.countdown__time {
    font-size: 16px;
  }
}

.countdown-wrap .countdown.item-4 .countdown__item span.countdown__text {
  display: block;
}

/*----------------------------------------*/
/*  16 - Components - Shipping
/*----------------------------------------*/
.uren-shipping_area {
  padding-top: 80px;
}

.uren-shipping_area .shipping-nav {
  border: 1px solid #ffc400;
  padding: 30px 0 25px;
}

@media (max-width: 991px) {
  .uren-shipping_area .shipping-nav {
    padding: 30px 30px 25px;
  }
}

.uren-shipping_area .shipping-nav .shipping-grid {
  flex: 0 0 20%;
  max-width: 20%;
}

@media (max-width: 1599px) {
  .uren-shipping_area .shipping-nav .shipping-grid {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .uren-shipping_area .shipping-nav .shipping-grid {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 1599px) {
  .uren-shipping_area .shipping-nav [class*="shipping-grid"]:last-child {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .uren-shipping_area .shipping-nav [class*="shipping-grid"]:last-child .shipping-item {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
}

.uren-shipping_area .shipping-nav .shipping-item {
  display: flex;
}

@media (max-width: 1599px) {
  .uren-shipping_area .shipping-nav .shipping-item {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .uren-shipping_area .shipping-nav .shipping-item {
    display: block;
    text-align: center;
  }
}

.uren-shipping_area .shipping-nav .shipping-item .shipping-icon {
  color: #ffc400;
  font-size: 40px;
  border-radius: 10px;
  width: 60px;
  height: 60px;
  line-height: 58px;
  display: block;
  text-align: center;
}

@media (max-width: 1599px) {
  .uren-shipping_area .shipping-nav .shipping-item .shipping-icon .shipping-icon {
    width: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .uren-shipping_area .shipping-nav .shipping-item .shipping-icon {
    width: 50px;
    height: 50px;
    line-height: 48px;
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .uren-shipping_area .shipping-nav .shipping-item .shipping-icon {
    width: auto;
  }
}

.uren-shipping_area .shipping-nav .shipping-item .shipping-content {
  border-right: 1px solid #e5e5e5;
  width: calc(100% - 100px);
  padding-left: 15px;
}

@media (max-width: 1599px) {
  .uren-shipping_area .shipping-nav .shipping-item .shipping-content {
    border-right: 0;
    width: auto;
    text-align: left;
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .uren-shipping_area .shipping-nav .shipping-item .shipping-content {
    text-align: center;
  }
}

.uren-shipping_area .shipping-nav .shipping-item .shipping-content > h6 {
  margin-bottom: 0;
  padding-bottom: 10px;
}

.uren-shipping_area .shipping-nav .shipping-item .shipping-content > p {
  margin-bottom: 0;
  font-size: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .uren-shipping_area .shipping-nav .shipping-item .shipping-content > p {
    font-size: 13px;
  }
}

.uren-shipping_area .shipping-nav .shipping-item .shipping-content.last-child {
  border-right: 0;
}

@media (max-width: 1599px) {
  .uren-shipping_area .shipping-nav .shipping-item .shipping-content.last-child {
    padding-bottom: 0;
  }
}

/*----------------------------------------*/
/*  18 - Components - Form
/*----------------------------------------*/
/* ---Uren's Newsletter Form--- */
.newsletter-form_wrap {
  padding-top: 55px;
  width: 770px;
}

@media (max-width: 991px) {
  .newsletter-form_wrap {
    width: 100%;
  }
}

.newsletter-form_wrap .subscribe-form {
  position: relative;
}

@media (max-width: 575px) {
  .newsletter-form_wrap .subscribe-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}

.newsletter-form_wrap .subscribe-form .newsletter-input {
  border: 1px solid #e5e5e5;
  height: 50px;
  width: 100%;
  padding: 0 210px 0 20px;
}

@media (max-width: 575px) {
  .newsletter-form_wrap .subscribe-form .newsletter-input {
    padding: 0 20px;
    margin-bottom: 30px;
  }
}

.newsletter-form_wrap .subscribe-form .newsletter-btn {
  font-weight: 700;
  background-color: #ffc400;
  text-transform: uppercase;
  position: absolute;
  font-size: 16px;
  top: 0;
  right: 0;
  width: 190px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

@media (max-width: 575px) {
  .newsletter-form_wrap .subscribe-form .newsletter-btn {
    position: relative;
  }
}

.newsletter-form_wrap .subscribe-form .newsletter-btn:hover {
  background-color: #e52e06;
  color: #ffffff;
}

/*----------------------------------------*/
/*  19 - Components - Social Link
/*----------------------------------------*/
.uren-social_link {
  padding-top: 25px;
}

.uren-social_link > ul > li {
  display: inline-block;
  padding-right: 10px;
}

.uren-social_link > ul > li:last-child {
  padding-right: 0;
}

.uren-social_link > ul > li > a {
  color: #ffffff;
  border-radius: 100%;
  font-size: 16px;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

@media (max-width: 479px) {
  .uren-social_link > ul > li > a {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}

.uren-social_link > ul > li:hover > a {
  background-color: #ffc400 !important;
  color: #ffffff !important;
}

.uren-social_link > ul > li.facebook > a {
  background-color: #3b579d;
}

.uren-social_link > ul > li.twitter > a {
  background-color: #3acaff;
}

.uren-social_link > ul > li.google-plus > a {
  background-color: #dd4b39;
}

.uren-social_link > ul > li.instagram > a {
  background-color: #833ab4;
}

.uren-social_link > ul > li.youtube > a {
  background-color: #d72525;
}

/*----------------------------------------*/
/*  17 - Components - Product Tab
/*----------------------------------------*/
/* ---Uren's Product Tab Area--- */
.sp-product-tab_area {
  background-color: #ffffff;
  padding: 0 0 60px;
}

.sp-product-tab_area .short-desc {
  margin: 0 auto;
  text-align: center;
}

.sp-product-tab_area .short-desc > p {
  margin-bottom: 0;
  padding-bottom: 15px;
}

.sp-product-tab_area .product-tab {
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-bottom: 25px;
}

@media (max-width: 767px) {
  .sp-product-tab_area .product-tab {
    display: block;
  }
}

@media (max-width: 767px) {
  .sp-product-tab_area .product-tab .uren-tab_title {
    padding-bottom: 20px;
  }
}

.sp-product-tab_area .product-tab .uren-tab_title > h4 {
  text-transform: uppercase;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .sp-product-tab_area .product-tab .uren-tab_title > h4 {
    font-size: 20px;
  }
}

@media (max-width: 479px) {
  .sp-product-tab_area .product-tab > ul.product-menu {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.sp-product-tab_area .product-tab > ul.product-menu > li > a {
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 30px;
  position: relative;
  display: block;
}

@media (max-width: 991px) {
  .sp-product-tab_area .product-tab > ul.product-menu > li > a {
    padding: 0 15px;
    font-size: 14px;
  }
}

@media (max-width: 479px) {
  .sp-product-tab_area .product-tab > ul.product-menu > li > a {
    padding: 0 0 10px 0;
  }
}

.sp-product-tab_area .product-tab > ul.product-menu > li > a.active {
  color: #ffc400;
}

.sp-product-tab_area .product-tab > ul.product-menu > li > a:after {
  background-color: #cccccc;
  content: "";
  position: absolute;
  top: 50%;
  right: -9px;
  height: 2px;
  width: 18px;
}

@media (max-width: 991px) {
  .sp-product-tab_area .product-tab > ul.product-menu > li > a:after {
    width: 10px;
    right: -5px;
  }
}

@media (max-width: 575px) {
  .sp-product-tab_area .product-tab > ul.product-menu > li > a:after {
    display: none;
  }
}

.sp-product-tab_area .product-tab > ul.product-menu > li:last-child > a {
  padding-right: 0;
}

@media (max-width: 479px) {
  .sp-product-tab_area .product-tab > ul.product-menu > li:last-child > a {
    padding: 0;
  }
}

.sp-product-tab_area .product-tab > ul.product-menu > li:last-child > a:after {
  display: none;
}

/*----------------------------------------*/
/*  20 - Components - Modal
/*----------------------------------------*/
/* ---Modal--- */
.modal-wrapper {
  display: block !important;
}

.modal-wrapper.modal {
  visibility: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  z-index: -9;
}

.modal-wrapper.show {
  background-color: rgba(0, 0, 0, 0.9);
  visibility: visible;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  z-index: 1050;
}

.modal-wrapper .modal-dialog {
  max-width: 86%;
}

@media (max-width: 575px) {
  .modal-wrapper .modal-dialog {
    max-width: 100%;
    left: 0;
  }
}

.modal-wrapper .modal-dialog .modal-content {
  padding: 30px 30px 25px;
  height: 755px;
  overflow-y: auto;
}

@media (max-width: 1500px) {
  .modal-wrapper .modal-dialog .modal-content {
    height: 505px;
    overflow-y: auto;
    border-radius: 0;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body {
  padding: 0;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .close {
  font-size: 30px;
  font-weight: 400;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  position: absolute;
  right: 15px;
  top: 0;
  z-index: 99;
}

@media (max-width: 1199px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .close {
    font-size: 25px;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area {
  border-bottom: 1px solid #e5e5e5;
  padding: 0 0 30px !important;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-img_slider {
  border: 1px solid #e5e5e5;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-img_slider-nav {
  padding: 0 60px;
  margin-top: 30px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-img_slider-nav .slick-slide img {
  border: 1px solid #e5e5e5;
  width: 100%;
  opacity: 0.7;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-img_slider-nav .slick-slide.slick-current img {
  border-color: #ffc400;
  opacity: 1;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-img_slider-nav .slick-slide:hover img {
  opacity: 1;
}

@media (max-width: 991px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content {
    padding-top: 25px;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .sp-heading > h5 {
  margin-bottom: 0;
  padding-bottom: 20px;
}

@media (max-width: 1199px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .sp-heading > h5 {
    font-size: 18px;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .rating-box {
  padding-bottom: 15px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .rating-box > ul > li {
  display: inline-block;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .price-box {
  padding-bottom: 10px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .price-box .new-price {
  font-size: 24px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .price-box .new-price {
  font-size: 16px;
  color: #0886cf;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .price-box .new-price.new-price-2 {
  color: #e52e06;
  font-weight: 700;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .price-box .old-price {
  color: #bababa;
  text-decoration: line-through;
  font-size: 14px;
  margin-left: 10px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .essential_stuff {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .color-list_area {
  margin-top: 15px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .quantity {
  padding-top: 15px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-group_btn {
  padding-top: 22px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-group_btn > ul > li {
  display: inline-block;
  margin-left: 5px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-group_btn > ul > li:first-child {
  margin-left: 0;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-group_btn > ul > li > a {
  border: 2px solid #e5e5e5;
  width: 50px;
  height: 50px;
  line-height: 47px;
  display: block;
  text-align: center;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-group_btn > ul > li > a.add-to_cart {
  background-color: #333333;
  border: 2px solid #333333;
  color: #ffffff;
  width: 140px;
}

@media (max-width: 575px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-group_btn > ul > li > a.add-to_cart {
    width: 110px;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-group_btn > ul > li > a.add-to_cart:hover {
  background-color: #ffc400;
  color: #ffffff !important;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-group_btn > ul > li > a > i {
  font-size: 18px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-group_btn > ul > li > a:hover {
  border-color: #ffc400;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-tag-line {
  display: flex;
  padding-top: 20px;
  align-items: center;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-tag-line h6 {
  margin-bottom: 0;
  padding-right: 5px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-tag-line > span {
  padding-right: 5px;
}

@media (max-width: 575px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-tag-line > a {
    font-size: 14px;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn {
  padding-top: 17px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li {
  display: inline-block;
  opacity: 0.8;
  position: relative;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li > a {
  background: #3b579d;
  color: #ffffff;
  padding: 2px 5px;
  border-radius: 2px;
  font-size: 14px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li > a:hover {
  color: #ffffff !important;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li > a > i {
  padding-right: 5px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li:nth-child(2) > a {
  background-color: #3acaff;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li:nth-child(3) > a {
  background-color: #fe6d4c;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li:hover {
  opacity: 1;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li .dropdown {
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.2);
  width: 165px;
  padding: 4px;
  transform-origin: 0 0 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  bottom: 25px;
  left: 0;
  z-index: 99;
}

@media (max-width: 575px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li .dropdown {
    left: auto;
    right: 0;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li .dropdown > li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li .dropdown > li:hover {
  background-color: #3b579d;
  color: #ffffff;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li .dropdown > li > i {
  text-align: center;
  flex-basis: 30px;
  width: 30px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .uren-social_btn > ul > li:hover > .dropdown {
  opacity: 1;
  visibility: visible;
}

/*----------------------------------------*/
/*  21 - Components - Brand
/*----------------------------------------*/
.uren-brand_area {
  padding: 75px 0 80px;
}

.brand-slider {
  border: 1px solid #e5e5e5;
}

.brand-slider .slide-item .inner-slide .single-product {
  border-right: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.brand-slider .last-active .inner-slide .single-product {
  border-right: 0;
}

/*----------------------------------------*/
/*  22 - Components - Breadcrumb
/*----------------------------------------*/
.breadcrumb-area {
  background-image: url("../images/breadcrumb/1.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 225px;
  position: relative;
}

@media (max-width: 575px) {
  .breadcrumb-area {
    background-position: right;
  }
}

.breadcrumb-area .breadcrumb-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@media (max-width: 575px) {
  .breadcrumb-area .breadcrumb-content {
    width: 100%;
  }
}

.breadcrumb-area .breadcrumb-content > h2 {
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  font-size: 36px;
  margin-bottom: 0;
  padding-bottom: 20px;
}

@media (max-width: 1199px) {
  .breadcrumb-area .breadcrumb-content > h2 {
    font-size: 20px;
  }
}

.breadcrumb-area .breadcrumb-content ul > li {
  color: #ffffff;
  display: inline-block;
  padding-left: 20px;
  position: relative;
}

.breadcrumb-area .breadcrumb-content ul > li:first-child {
  padding-left: 0;
}

.breadcrumb-area .breadcrumb-content ul > li:before {
  content: "\f054";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  position: absolute;
  right: -15px;
  top: 2px;
  font-size: 10px;
  color: #ffffff;
}

.breadcrumb-area .breadcrumb-content ul > li:last-child:before {
  display: none;
}

.breadcrumb-area .breadcrumb-content ul > li > a {
  color: #ffffff;
}

/*----------------------------------------*/
/*  23 - Components - Popular Search
/*----------------------------------------*/
.popular-search_area {
  padding: 25px 0 20px;
}

.popular-search_area .popular-search {
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .popular-search_area .popular-search {
    text-align: center;
    display: block;
  }
}

.popular-search_area .popular-search label {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: #e52e06;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .popular-search_area .popular-search label {
    display: block;
  }
}

.popular-search_area .popular-search a {
  margin-left: 5px;
}

/*-------------------------------------------*/
/*  24 - Components - Featured Categories
/*-------------------------------------------*/
.featured-categories_area {
  padding: 75px 0 0;
}

.featured-categories_area .featured-categories_slider .slide-item .slide-inner,
.featured-categories_area .featured-categories_slider-2 .slide-item .slide-inner {
  background-color: #fcfcfc;
  border: 1px solid #ededed;
  display: flex;
  padding: 60px 0;
}

@media (max-width: 575px) {
  .featured-categories_area .featured-categories_slider .slide-item .slide-inner,
  .featured-categories_area .featured-categories_slider-2 .slide-item .slide-inner {
    display: block;
  }
}

.featured-categories_area .featured-categories_slider .slide-item .slide-inner .slide-image_area,
.featured-categories_area .featured-categories_slider-2 .slide-item .slide-inner .slide-image_area {
  width: 40%;
}

@media (max-width: 575px) {
  .featured-categories_area .featured-categories_slider .slide-item .slide-inner .slide-image_area,
  .featured-categories_area .featured-categories_slider-2 .slide-item .slide-inner .slide-image_area {
    width: 100%;
  }
}

.featured-categories_area .featured-categories_slider .slide-item .slide-inner .slide-image_area > a img,
.featured-categories_area .featured-categories_slider-2 .slide-item .slide-inner .slide-image_area > a img {
  width: 100%;
}

.featured-categories_area .featured-categories_slider .slide-item .slide-inner .slide-content_area,
.featured-categories_area .featured-categories_slider-2 .slide-item .slide-inner .slide-content_area {
  width: 60%;
  padding-left: 15px;
}

@media (max-width: 575px) {
  .featured-categories_area .featured-categories_slider .slide-item .slide-inner .slide-content_area,
  .featured-categories_area .featured-categories_slider-2 .slide-item .slide-inner .slide-content_area {
    width: 100%;
    padding: 15px 0 0 15px;
  }
}

.featured-categories_area .featured-categories_slider .slide-item .slide-inner .slide-content_area > h3,
.featured-categories_area .featured-categories_slider-2 .slide-item .slide-inner .slide-content_area > h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.featured-categories_area .featured-categories_slider .slide-item .slide-inner .slide-content_area > span,
.featured-categories_area .featured-categories_slider-2 .slide-item .slide-inner .slide-content_area > span {
  font-size: 16px;
  color: #0886cf;
  padding-bottom: 15px;
  display: block;
}

.featured-categories_area .featured-categories_slider .slide-item .slide-inner .slide-content_area .product-item > li > a > i,
.featured-categories_area .featured-categories_slider-2 .slide-item .slide-inner .slide-content_area .product-item > li > a > i {
  font-size: 12px;
}

.featured-categories_area .featured-categories_slider .slide-item .slide-inner .slide-content_area .uren-btn-ps_left,
.featured-categories_area .featured-categories_slider-2 .slide-item .slide-inner .slide-content_area .uren-btn-ps_left {
  padding-top: 20px;
}

.featured-categories_area .featured-categories_slider .slide-item .slide-inner .slide-content_area .uren-btn-ps_left .uren-btn,
.featured-categories_area .featured-categories_slider-2 .slide-item .slide-inner .slide-content_area .uren-btn-ps_left .uren-btn {
  width: 140px;
}

.featured-categories_area.featured-categories_area-2 {
  padding: 80px 0;
}

.featured-categories_area.featured-categories_area-2 .featured-categories_slider-2 {
  border: 1px solid #e5e5e5;
}

.featured-categories_area.featured-categories_area-2 .featured-categories_slider-2 .slide-item .slide-inner {
  display: block;
  text-align: center;
  padding: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
}

.featured-categories_area.featured-categories_area-2 .featured-categories_slider-2 .slide-item .slide-inner .slide-image_area {
  width: 100%;
}

.featured-categories_area.featured-categories_area-2 .featured-categories_slider-2 .slide-item .slide-inner .slide-content_area {
  padding-left: 0;
  width: 100%;
}

/*-------------------------------------------*/
/*  25 - Components - Slider Navigation
/*-------------------------------------------*/
.slider-navigation_style-1 .slick-arrow,
.slider-navigation_style-3 .slick-arrow {
  background-color: #e9e9e9;
  border: 1px solid transparent;
  font-size: 24px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  color: #333333;
  text-align: center;
  position: absolute;
  z-index: 8;
  cursor: pointer;
  border-radius: 5px;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  left: 20px;
}

@media (max-width: 767px) {
  .slider-navigation_style-1 .slick-arrow,
  .slider-navigation_style-3 .slick-arrow {
    display: none !important;
  }
}

.slider-navigation_style-1 .slick-arrow:hover,
.slider-navigation_style-3 .slick-arrow:hover {
  background-color: #ffffff;
  border-color: #ffc400;
}

.slider-navigation_style-1 .slick-next,
.slider-navigation_style-1 .tty-slick-text-next,
.slider-navigation_style-3 .slick-next,
.slider-navigation_style-3 .tty-slick-text-next {
  left: auto;
  right: 20px;
}

.slider-navigation_style-1:hover .slick-arrow,
.slider-navigation_style-3:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.slider-navigation_style-2 .slick-arrow {
  font-size: 55px;
  display: block;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  position: absolute;
  z-index: 8;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  left: 20px;
}

.slider-navigation_style-2 .slick-arrow:hover {
  color: #ffc400;
}

.slider-navigation_style-2 .slick-next {
  left: auto;
  right: 20px;
}

.slider-navigation_style-2:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.slider-navigation_style-3 .slick-arrow {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
}

.slider-navigation_style-3 .slick-arrow:hover {
  color: #ffc400;
}

.slider-navigation_style-4 .slick-arrow {
  background-color: #ffc400;
  color: #ffffff;
  display: flex;
  justify-content: center;
  width: 100%;
}

.slider-navigation_style-4 .slick-arrow:hover {
  background-color: #e52e06;
}

.slider-navigation_style-4 .slick-arrow.tty-slick-text-prev {
  margin-bottom: 5px;
}

.slider-navigation_style-4 .slick-arrow.tty-slick-text-next {
  margin-top: 5px;
}

.slider-navigation_style-4 .slick-arrow > i {
  transform: rotate(87deg);
  display: block;
}

/*-------------------------------------------*/
/*  26 - Components - Testimonial
/*-------------------------------------------*/
.testimonial-area {
  background-image: url("../images/testimonial/bg-1.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 75px 0 80px;
  margin-top: 80px;
}

.testimonial-area .testimonial-slider .slide-item {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.testimonial-area .testimonial-slider .slide-item .slide-inner .single-slide .slide-content span.primary-text_color {
  padding-bottom: 10px;
  display: block;
}

.testimonial-area .testimonial-slider .slide-item .slide-inner .single-slide .slide-content > h3.user-name {
  color: #ffffff;
  margin-bottom: 0;
  padding-bottom: 55px;
}

.testimonial-area .testimonial-slider .slide-item .slide-inner .single-slide .slide-content .comment-box {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 50px 0;
  width: 1170px;
  margin: 0 auto;
}

@media (max-width: 1500px) {
  .testimonial-area .testimonial-slider .slide-item .slide-inner .single-slide .slide-content .comment-box {
    width: auto;
  }
}

.testimonial-area .testimonial-slider .slide-item .slide-inner .single-slide .slide-content .comment-box > p.user-feedback {
  color: #ffffff;
  font-size: 24px;
  line-height: 40px;
  margin: 0 auto;
  max-width: 90%;
}

@media (max-width: 767px) {
  .testimonial-area .testimonial-slider .slide-item .slide-inner .single-slide .slide-content .comment-box > p.user-feedback {
    font-size: 15px;
    line-height: 28px;
  }
}

@media (max-width: 479px) {
  .testimonial-area .testimonial-slider .slide-item .slide-inner .single-slide .slide-content .comment-box > p.user-feedback {
    font-size: 14px;
  }
}

.testimonial-area .testimonial-slider .slide-item .slide-inner .single-slide .slide-image {
  padding: 30px 0 0;
  display: flex;
  justify-content: center;
}

/*----------------------------------------*/
/*  25 - Section - Header
/*----------------------------------------*/
/* ---Header Top Area--- */
.header-top_area .container-fluid {
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top_area .container-fluid .col-xl-7.col-lg-8,
  .header-top_area .container-fluid .col-xl-8.col-lg-7 {
    position: static;
  }
}

.header-top_area .main-menu_area {
  display: flex;
  justify-content: flex-start;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top_area .main-menu_area {
    position: static !important;
  }
}

.header-top_area .main-menu_area > nav > ul > li {
  display: inline-block;
  padding-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top_area .main-menu_area > nav > ul > li {
    padding-right: 10px;
  }
}

.header-top_area .main-menu_area > nav > ul > li:hover > a {
  color: #ffc400;
}

.header-top_area .main-menu_area > nav > ul > li:last-child {
  padding-right: 0;
}

.header-top_area .main-menu_area > nav > ul > li .hm-dropdown {
  position: absolute;
  background: #ffffff;
  width: 230px;
  padding: 20px 0 24px;
  transform-origin: 0 0 0;
  transform: scaleY(0);
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 9;
}

.header-top_area .main-menu_area > nav > ul > li .hm-dropdown > li > a {
  padding: 10px 20px;
  display: block;
}

.header-top_area .main-menu_area > nav > ul > li .hm-dropdown > li.active > a {
  color: #ffc400;
}

.header-top_area .main-menu_area > nav > ul > li:hover .hm-dropdown {
  transform: scaleY(1);
}

.header-top_area .main-menu_area > nav > ul > li > ul > li {
  position: relative;
}

.header-top_area .main-menu_area > nav > ul > li > ul > li > ul.hm-sub_dropdown {
  top: 30px;
  left: 100%;
  opacity: 0;
  visibility: hidden;
}

.header-top_area .main-menu_area > nav > ul > li > ul > li > a {
  display: block;
}

.header-top_area .main-menu_area > nav > ul > li > ul > li > a > i {
  position: absolute;
  top: 14px;
  right: 25px;
  display: block;
  font-size: 12px;
  transform: rotate(0);
}

.header-top_area .main-menu_area > nav > ul > li > ul > li:hover > .hm-sub_dropdown {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.header-top_area .main-menu_area > nav > ul > li > ul > li:hover > a > i {
  transform: rotate(-90deg);
}

.header-top_area .main-menu_area > nav > ul > li.megamenu-holder .hm-megamenu {
  background: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px;
  transform-origin: 0 0 0;
  transform: scaleY(0);
  position: absolute;
  width: 100%;
  left: 0;
  top: auto;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 99;
}

.header-top_area .main-menu_area > nav > ul > li.megamenu-holder:hover .hm-megamenu {
  transform: scaleY(1);
}

.header-top_area .main-menu_area > nav > ul > li.megamenu-holder > ul > li {
  width: 33.33%;
}

.header-top_area .main-menu_area > nav > ul > li.megamenu-holder > ul > li > span.megamenu-title {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  padding-bottom: 15px;
}

.header-top_area .main-menu_area > nav > ul > li.megamenu-holder > ul > li > ul > li > a {
  display: block;
  line-height: 35px;
  font-size: 16px;
}

.header-top_area .main-menu_area > nav > ul > li > a {
  font-weight: 700;
  color: #ffffff;
  display: block;
  padding: 23px 0;
  text-transform: uppercase;
  position: relative;
}

.header-top_area .main-menu_area > nav > ul > li > a > i {
  padding-left: 5px;
  font-size: 18px;
  vertical-align: middle;
}

.header-top_area .ht-menu > ul {
  display: flex;
  justify-content: flex-end;
}

.header-top_area .ht-menu > ul > li {
  display: inline-block;
  position: relative;
}

.header-top_area .ht-menu > ul > li:hover .ht-dropdown {
  transform: scaleY(1);
}

.header-top_area .ht-menu > ul > li:hover > a {
  color: #ffc400;
}

.header-top_area .ht-menu > ul > li .ht-dropdown {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 0 0 0;
  position: absolute;
  top: 100%;
  left: auto;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  width: 150px;
  padding: 10px;
  right: 0;
  z-index: 99;
}

.header-top_area .ht-menu > ul > li .ht-dropdown > li.active a {
  color: #ffc400;
}

.header-top_area .ht-menu > ul > li .ht-dropdown > li:hover a {
  color: #ffc400;
}

.header-top_area .ht-menu > ul > li .ht-dropdown > li a {
  border-bottom: 1px solid #e5e5e5;
  display: block;
  padding: 10px 5px;
  line-height: 25px;
  font-size: 12px;
}

.header-top_area .ht-menu > ul > li .ht-dropdown > li a img {
  margin-right: 5px;
}

.header-top_area .ht-menu > ul > li .ht-dropdown > li:first-child > a {
  padding-top: 0;
}

.header-top_area .ht-menu > ul > li .ht-dropdown > li:last-child > a {
  border-bottom: 0;
  padding-bottom: 0;
}

.header-top_area .ht-menu > ul > li > a {
  color: #ffffff;
  padding: 23px 15px;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top_area .ht-menu > ul > li > a {
    padding: 23px 6px;
  }
}

.header-top_area .ht-menu > ul > li > a > i {
  padding-left: 5px;
  font-size: 11px;
}

.header-top_area .ht-menu > ul > li:last-child > a {
  padding-right: 0;
}

.header-top_area.header-sticky {
  display: none;
}

/* ---Header Middle Area--- */
@media (max-width: 1599px) {
  .header-middle_area {
    padding-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .header-middle_area {
    padding: 30px 0;
  }
}

.header-middle_area .header-logo_area {
  padding: 20px 0;
}

@media (max-width: 991px) {
  .header-middle_area .header-logo_area {
    padding: 10px 0 0;
  }
}

@media (max-width: 479px) {
  .header-middle_area .header-logo_area {
    text-align: center;
  }
}

.header-middle_area .hm-form_area {
  display: flex;
  justify-content: flex-end;
}

.hm-searchbox {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  display: flex;
  position: relative;
  border-radius: 5px;
  height: 55px;
  line-height: 55px;
  width: 100%;
}

.hm-searchbox .nice-select.select-search-category {
  width: 150px;
  line-height: 55px;
  height: 52px;
  margin: 0;
  border: 0;
  padding: 0 28px 0 25px;
  font-size: 13px;
  border-radius: 15px 0 0 15px;
  position: relative;
}

.hm-searchbox .nice-select.select-search-category > span.current {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.select-search-category ul.list {
  height: 440px;
  overflow-y: auto;
  overscroll-behavior-x: none;
}

.hm-searchbox .current:after {
  background: #e5e5e5;
  position: absolute;
  display: block;
  content: "";
  height: 22px;
  width: 1px;
  top: 50%;
  right: -10px;
  font-size: 13px;
  transform: translateY(-50%);
}

.hm-searchbox .nice-select.select-search-category:before {
  right: 30px;
  top: 25px;
}

.hm-searchbox input {
  font-size: 13px;
  height: 55px;
  background: transparent;
  border: none;
  width: 100%;
  padding: 0 33px 0;
}

.hm-searchbox .header-search_btn {
  border-radius: 0 5px 5px 0;
  text-transform: uppercase;
  background: #ffc400;
  width: 250px;
  margin: -1px;
}

.hm-searchbox .header-search_btn > i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: 700;
}

@media (max-width: 1500px) {
  .hm-searchbox .header-search_btn > i {
    font-size: 16px;
  }
}

.hm-searchbox .header-search_btn > i > span {
  font-size: 16px;
  padding-left: 10px;
  font-style: normal;
}

@media (max-width: 1500px) {
  .hm-searchbox .header-search_btn > i > span {
    font-size: 13px;
    padding-left: 5px;
    text-transform: capitalize;
  }
}

.hm-searchbox:hover .header-search_btn:hover {
  background-color: #e52e06;
  color: #ffffff;
}

.header-right_area > ul {
  display: flex;
  justify-content: space-between;
}

.header-right_area > ul > li {
  border-radius: 5px;
}

@media (max-width: 991px) {
  .header-right_area > ul > li.mobile-menu_wrap {
    margin-right: 30px;
  }
  .header-right_area > ul > li.mobile-menu_wrap > a {
    color: #ffffff;
    font-size: 60px;
  }
}

.header-right_area > ul > li.minicart-wrap {
  background: #ffffff;
  width: 42%;
  text-align: center;
  height: 55px;
  line-height: 55px;
  margin-right: 30px;
}

@media (max-width: 767px) {
  .header-right_area > ul > li.minicart-wrap {
    width: 75%;
    margin-right: 0;
  }
}

.header-right_area > ul > li.minicart-wrap:hover {
  background-color: #ffc400;
}

.header-right_area > ul > li.minicart-wrap > a.minicart-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.header-right_area > ul > li.minicart-wrap > a.minicart-btn .minicart-count_area {
  position: relative;
}

.header-right_area > ul > li.minicart-wrap > a.minicart-btn .minicart-count_area .item-count {
  background-color: #e52e06;
  color: #ffffff;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 100%;
  top: 10px;
  left: 25px;
  font-size: 12px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .header-right_area > ul > li.minicart-wrap > a.minicart-btn .minicart-count_area .item-count {
    top: 2px;
    left: 17px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right_area > ul > li.minicart-wrap > a.minicart-btn .minicart-count_area .item-count {
    top: 2px;
    left: 17px;
  }
}

.header-right_area > ul > li.minicart-wrap > a.minicart-btn .minicart-count_area > i {
  padding-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .header-right_area > ul > li.minicart-wrap > a.minicart-btn .minicart-count_area > i {
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right_area > ul > li.minicart-wrap > a.minicart-btn .minicart-count_area > i {
    padding-right: 15px;
  }
}

.header-right_area > ul > li.minicart-wrap > a.minicart-btn .minicart-front_text span {
  line-height: 22px;
  display: block;
  font-size: 18px;
  text-align: left;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .header-right_area > ul > li.minicart-wrap > a.minicart-btn .minicart-front_text span {
    line-height: 18px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right_area > ul > li.minicart-wrap > a.minicart-btn .minicart-front_text span {
    line-height: 18px;
    font-size: 15px;
  }
}

.header-right_area > ul > li.minicart-wrap > a.minicart-btn .minicart-front_text span.total-price {
  font-weight: 700;
  color: #333333;
}

.header-right_area > ul > li.minicart-wrap > a.minicart-btn:before {
  content: "\f3d0";
  font-family: "Ionicons";
  position: absolute;
  right: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .header-right_area > ul > li.minicart-wrap > a.minicart-btn:before {
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right_area > ul > li.minicart-wrap > a.minicart-btn:before {
    right: 12px;
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-right_area > ul > li.minicart-wrap > a.minicart-btn:before {
    right: 15px;
    font-size: 18px;
  }
}

.header-right_area > ul > li.minicart-wrap > a:hover {
  color: #333333 !important;
}

.header-right_area > ul > li.contact-us_wrap {
  background-color: #e52e06;
  width: 58%;
  height: 55px;
  line-height: 55px;
  text-align: center;
  font-weight: 700;
  position: relative;
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .header-right_area > ul > li.contact-us_wrap {
    display: none;
  }
}

.header-right_area > ul > li.contact-us_wrap > a {
  padding: 0;
}

@media (max-width: 1500px) {
  .header-right_area > ul > li.contact-us_wrap > a {
    font-size: 18px;
  }
}

.header-right_area > ul > li.contact-us_wrap > a:before {
  content: "";
  border: 9px solid transparent;
  border-top-color: #e52e06;
  border-left-color: #e52e06;
  position: absolute;
  top: 99%;
  left: 10%;
}

.header-right_area > ul > li.contact-us_wrap > a > i {
  font-size: 24px;
  margin-right: 10px;
}

@media (max-width: 1500px) {
  .header-right_area > ul > li.contact-us_wrap > a > i {
    font-size: 18px;
    margin-right: 5px;
  }
}

.header-right_area > ul > li.contact-us_wrap:hover > a {
  color: #333333 !important;
}

.header-right_area > ul > li.contact-us_wrap > a {
  color: #ffffff;
}

.header-right_area > ul > li > a {
  color: #333333;
  display: block;
  font-size: 24px;
}

.header-right_area > ul > li > a > span {
  font-size: 16px;
}

.header-right_area > ul > li:last-child > a {
  padding-right: 0;
}

/* ---Header Main Area--- */
.header-main_area .custom-logo_col {
  flex: 0 0 15%;
  max-width: 15%;
}

@media (max-width: 1599px) {
  .header-main_area .custom-logo_col {
    flex: 0 0 60%;
    max-width: 60%;
    order: 1;
  }
}

@media (max-width: 991px) {
  .header-main_area .custom-logo_col {
    flex: 0 0 30%;
    max-width: 30%;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .header-main_area .custom-logo_col {
    flex: 0 0 35%;
    max-width: 35%;
  }
}

@media (max-width: 479px) {
  .header-main_area .custom-logo_col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.header-main_area .custom-category_col {
  flex: 0 0 17%;
  max-width: 17%;
}

@media (max-width: 1599px) {
  .header-main_area .custom-category_col {
    flex: 0 0 25%;
    max-width: 25%;
    order: 2;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-main_area .custom-category_col {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media (max-width: 991px) {
  .header-main_area .custom-category_col {
    flex: 0 0 35%;
    max-width: 35%;
  }
}

@media (max-width: 767px) {
  .header-main_area .custom-category_col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.header-main_area .custom-search_col {
  flex: 0 0 36%;
  max-width: 36%;
}

@media (max-width: 1599px) {
  .header-main_area .custom-search_col {
    flex: 0 0 75%;
    max-width: 75%;
    order: 2;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-main_area .custom-search_col {
    flex: 0 0 70%;
    max-width: 70%;
  }
}

@media (max-width: 991px) {
  .header-main_area .custom-search_col {
    flex: 0 0 65%;
    max-width: 65%;
  }
}

@media (max-width: 767px) {
  .header-main_area .custom-search_col {
    display: none;
  }
}

.header-main_area .custom-cart_col {
  flex: 0 0 32%;
  max-width: 32%;
}

@media (max-width: 1599px) {
  .header-main_area .custom-cart_col {
    flex: 0 0 40%;
    max-width: 40%;
    order: 1;
  }
}

@media (max-width: 991px) {
  .header-main_area .custom-cart_col {
    flex: 0 0 70%;
    max-width: 70%;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .header-main_area .custom-cart_col {
    flex: 0 0 65%;
    max-width: 65%;
  }
}

@media (max-width: 479px) {
  .header-main_area .custom-cart_col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* ---Header Sticky--- */
.header-main_area .header-sticky.sticky {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: all 300ms ease-in 0s;
  transition: all 300ms ease-in 0s;
  z-index: 1049;
}

@media (max-width: 575px) {
  .header-main_area .header-sticky.sticky {
    position: static;
  }
}

.header-main_area .header-sticky.sticky.header-top_area {
  display: none;
}

.header-main_area .header-sticky.sticky.sticky {
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
  animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
  display: block;
}

.header-main_area .header-sticky.sticky.header-sticky .header-right_area {
  padding: 9px 0;
}

.header-main_area .header-sticky.sticky.header-sticky .header-sticky_logo {
  padding-top: 25px;
}

/* ---Header Main Area Two--- */
@media (max-width: 991px) {
  .header-main_area-2 {
    padding-bottom: 30px;
  }
}

@media (max-width: 1599px) {
  .header-main_area-2 .header-middle_area .header-logo_area {
    padding: 35px 0 0;
  }
}

@media (max-width: 575px) {
  .header-main_area-2 .header-middle_area .header-logo_area {
    padding-top: 0;
    text-align: center;
  }
}

.header-main_area-2 .header-middle_area .hm-form_area {
  padding-top: 25px;
}

.header-main_area-2 .header-right_area {
  padding-top: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-main_area-2 .header-right_area > ul > li.minicart-wrap > a:before {
    right: 7px;
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-main_area-2 .header-right_area > ul > li.minicart-wrap > a .minicart-count_area > span.item-count {
    top: 2px;
    left: 11px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-main_area-2 .header-right_area > ul > li.minicart-wrap > a .minicart-count_area > i {
    padding-right: 15px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-main_area-2 .header-right_area > ul > li.minicart-wrap > a .minicart-front_text span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-main_area-2 .header-top_area .category-menu .category-heading > h2.categories-toggle {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .header-main_area-2 .header-top_area .main-menu_area > nav > ul > li {
    padding-right: 15px;
  }
}

@media (max-width: 1599px) {
  .header-main_area-2 .header-top_area .ht-right_area .ht-menu > ul > li a span:nth-child(2) {
    display: none;
  }
}

.header-main_area-2 .custom-category_col {
  flex: 0 0 18%;
  max-width: 18%;
  order: 1;
}

@media (max-width: 1599px) {
  .header-main_area-2 .custom-category_col {
    flex: 0 0 22%;
    max-width: 22%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-main_area-2 .custom-category_col {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (max-width: 991px) {
  .header-main_area-2 .custom-category_col {
    flex: 0 0 35%;
    max-width: 35%;
  }
}

@media (max-width: 767px) {
  .header-main_area-2 .custom-category_col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.header-main_area-2 .custom-menu_col {
  flex: 0 0 50%;
  max-width: 50%;
  order: 1;
}

@media (max-width: 1599px) {
  .header-main_area-2 .custom-menu_col {
    flex: 0 0 53%;
    max-width: 53%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-main_area-2 .custom-menu_col {
    flex: 0 0 58%;
    max-width: 58%;
  }
}

.header-main_area-2 .custom-setting_col {
  flex: 0 0 32%;
  max-width: 32%;
  order: 1;
}

@media (max-width: 1599px) {
  .header-main_area-2 .custom-setting_col {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-main_area-2 .custom-setting_col {
    flex: 0 0 17%;
    max-width: 17%;
  }
}

/* ---Header Main Area Three--- */
.header-main_area-3 .header-middle_area .header-logo_area {
  padding: 30px 0;
}

@media (max-width: 575px) {
  .header-main_area-3 .header-middle_area .header-logo_area {
    padding: 0;
  }
}

.header-main_area-3 .header-middle_area .hm-form_area {
  padding-top: 22px;
}

.header-main_area-3 .header-middle_area .header-right_area {
  padding-top: 22px;
}

.header-main_area-3 .header-middle_area .header-right_area > ul > li.mobile-menu_wrap > a {
  color: #222222;
}

.header-main_area-3 .header-middle_area .header-right_area > ul > li.minicart-wrap {
  border: 1px solid #ffc400;
}

.header-main_area-3 .header-middle_area .header-right_area > ul > li.contact-us_wrap {
  border: 1px solid #ffc400;
  background-color: transparent;
}

.header-main_area-3 .header-middle_area .header-right_area > ul > li.contact-us_wrap > a {
  color: #e52e06;
}

.header-main_area-3 .header-middle_area .header-right_area > ul > li.contact-us_wrap > a:before {
  display: none;
}

@media (max-width: 991px) {
  .header-main_area-3 .header-top_area {
    padding: 30px 0;
  }
}

@media (max-width: 767px) {
  .header-main_area-3 .header-top_area {
    padding: 0;
  }
}

.header-main_area-3 .header-top_area .category-menu .category-heading {
  background-color: #000;
  border-radius: 0;
}

.header-main_area-3 .header-top_area .category-menu .category-heading > h2:before {
  color: #ffffff;
}

.header-main_area-3 .header-top_area .category-menu .category-heading > h2:after {
  color: #ffffff;
}

.header-main_area-3 .header-top_area .category-menu .category-heading > h2 span {
  color: #ffffff;
}

.header-main_area-3 .header-top_area .main-menu_area > nav > ul > li > a {
  padding: 15px 0;
  color: #000;
}

.header-main_area-3 .header-top_area .main-menu_area > nav > ul > li:hover > a {
  color: #e52e06 !important;
}

.header-main_area-3 .header-top_area .ht-right_area .ht-menu > ul > li > a {
  padding: 15px 8px;
  color: #000;
}

@media (max-width: 1599px) {
  .header-main_area-3 .header-top_area .ht-right_area .ht-menu > ul > li > a span:nth-child(2) {
    display: none;
  }
}

.header-main_area-3 .header-top_area .ht-right_area .ht-menu > ul > li:hover > a {
  color: #e52e06 !important;
}

.header-main_area-3 .header-top_area .ht-right_area .ht-menu > ul > li:last-child > a {
  padding-right: 0;
}

.header-main_area-3 .header-sticky .main-menu_area > nav > ul > li > a {
  padding: 23.9px 0;
}

.header-main_area-3 .header-sticky .header-right_area {
  padding-top: 22px;
}

.header-main_area-3 .header-sticky .header-right_area > ul > li.minicart-wrap {
  background-color: #ffc400;
}

.header-main_area-3 .header-sticky .header-right_area > ul > li.contact-us_wrap {
  background-color: transparent;
}

.header-main_area-3 .header-sticky .header-right_area > ul > li.contact-us_wrap > a {
  color: #e52e06;
}

.header-main_area-3 .header-sticky .header-right_area > ul > li.contact-us_wrap > a:before {
  display: none;
}

.header-main_area-3 .custom-category_col {
  flex: 0 0 17%;
  max-width: 17%;
  order: 1;
}

@media (max-width: 1599px) {
  .header-main_area-3 .custom-category_col {
    flex: 0 0 24%;
    max-width: 24%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-main_area-3 .custom-category_col {
    flex: 0 0 35%;
    max-width: 35%;
  }
}

@media (max-width: 767px) {
  .header-main_area-3 .custom-category_col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.header-main_area-3 .custom-menu_col {
  flex: 0 0 54%;
  max-width: 54%;
  order: 1;
}

@media (max-width: 1599px) {
  .header-main_area-3 .custom-menu_col {
    flex: 0 0 58%;
    max-width: 58%;
  }
}

.header-main_area-3 .custom-setting_col {
  flex: 0 0 29%;
  max-width: 29%;
  order: 1;
}

@media (max-width: 1599px) {
  .header-main_area-3 .custom-setting_col {
    flex: 0 0 18%;
    max-width: 18%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-main_area-3 .custom-search_col {
    flex: 0 0 65%;
    max-width: 65%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-main_area-3 .custom-search_col .hm-form_area form.hm-searchbox button.header-search_btn {
    background-color: #222222;
    color: #ffffff !important;
  }
  .header-main_area-3 .custom-search_col .hm-form_area form.hm-searchbox button.header-search_btn:hover {
    background-color: #e52e06;
  }
}

.header-main_area-3 .header-sticky {
  padding: 0;
}

.header-main_area-3 .header-sticky .header-right_area > ul > li:hover.mobile-menu_wrap > a {
  color: #222222 !important;
}

/*----------------------------------------*/
/*  26 - Section - Footer
/*----------------------------------------*/
.footer-top_area {
  background-image: url("../images/footer/newsletter/bg-1.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 75px 0;
}

.footer-top_area .newsletter-area {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer-top_area .newsletter-area > h3.title {
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 0;
  font-size: 34px;
}

@media (max-width: 575px) {
  .footer-top_area .newsletter-area > h3.title {
    font-size: 30px;
    text-align: center;
    line-height: 40px;
  }
}

.footer-top_area .newsletter-area > p.short-desc {
  color: #ffffff;
  padding-top: 10px;
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .footer-top_area .newsletter-area > p.short-desc {
    text-align: center;
  }
}

.footer-middle_area {
  background-color: #222222;
  padding: 80px 0;
}

.footer-middle_area .footer-widgets_info .footer-widgets_logo {
  padding-bottom: 25px;
}

.footer-middle_area .footer-widgets_info .widget-short_desc > p {
  padding-bottom: 30px;
  margin-bottom: 0;
}

.footer-middle_area .footer-widgets_info .widgets-essential_stuff > ul > li {
  padding-bottom: 15px;
}

.footer-middle_area .footer-widgets_info .widgets-essential_stuff > ul > li span {
  color: #ffffff;
  font-size: 16px;
}

.footer-middle_area .footer-widgets_info .widgets-essential_stuff > ul > li:last-child {
  padding-bottom: 0;
}

.footer-middle_area .footer-widgets_info .widgets-essential_stuff > ul > li > i {
  font-size: 21px;
  padding-right: 10px;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .footer-middle_area .footer-widgets_area [class*="col-"] {
    padding-top: 30px;
  }
}

.footer-middle_area .footer-widgets_title h3 {
  text-transform: uppercase;
  color: #ffffff;
  padding-bottom: 20px;
  margin-bottom: 25px;
  position: relative;
  font-size: 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-middle_area .footer-widgets_title h3 {
    text-transform: capitalize;
    font-size: 14px;
  }
}

.footer-middle_area .footer-widgets_title h3:before {
  content: "";
  background-color: #ffc400;
  position: absolute;
  height: 1px;
  width: 70px;
  bottom: 0;
}

.footer-middle_area .short-desc > p {
  margin-bottom: 0;
  padding-bottom: 20px;
}

.footer-middle_area .footer-widgets > ul > li {
  padding-bottom: 10px;
}

.footer-middle_area .footer-widgets > ul > li:last-child {
  padding-bottom: 0;
}

/* ---Footer Bottom--- */
.footer-bottom_area {
  background-color: #222222;
}

.footer-bottom_area .footer-bottom_nav {
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  padding: 25px 0;
}

@media (max-width: 767px) {
  .footer-bottom_area .footer-bottom_nav {
    padding: 20px 0;
  }
}

.footer-bottom_area .footer-bottom_nav .copyright {
  text-align: left;
}

@media (max-width: 767px) {
  .footer-bottom_area .footer-bottom_nav .copyright {
    text-align: center;
    padding-bottom: 15px;
  }
}

.footer-bottom_area .footer-bottom_nav .payment {
  text-align: right;
}

@media (max-width: 767px) {
  .footer-bottom_area .footer-bottom_nav .payment {
    text-align: center;
    padding-bottom: 10px;
  }
}

/*----------------------------------------*/
/*  29 - Pages - Shop
/*----------------------------------------*/
.shop-content_wrapper {
  padding-top: 55px;
}

.uren-sidebar-catagories_area .uren-sidebar_categories {
  background-color: #f4f4f4;
  padding: 25px;
  margin-bottom: 60px;
}

.uren-sidebar-catagories_area .uren-sidebar_categories .uren-categories_title {
  border-bottom: 2px solid #e5e5e5;
  position: relative;
}

.uren-sidebar-catagories_area .uren-sidebar_categories .uren-categories_title:before {
  content: "";
  background-color: #ffc400;
  width: 70px;
  height: 2px;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.uren-sidebar-catagories_area .uren-sidebar_categories .uren-categories_title h5 {
  text-transform: uppercase;
  margin-bottom: 0;
  padding-bottom: 25px;
}

.uren-sidebar-catagories_area .uren-sidebar_categories .uren-categories_title h5:after {
  height: 2px;
  bottom: -2px;
}

.uren-sidebar-catagories_area .uren-sidebar_categories .sidebar-checkbox_list > li {
  padding-bottom: 15px;
}

.uren-sidebar-catagories_area .uren-sidebar_categories .sidebar-checkbox_list > li:first-child {
  padding-top: 20px;
}

.uren-sidebar-catagories_area .uren-sidebar_categories .sidebar-checkbox_list > li > a {
  display: flex;
  justify-content: space-between;
}

.uren-sidebar-catagories_area .uren-sidebar_categories.category-module {
  padding: 0;
}

.uren-sidebar-catagories_area .uren-sidebar_categories.category-module .category-module_heading {
  padding: 25px 25px 0;
}

.uren-sidebar-catagories_area .uren-sidebar_categories.category-module .category-module_heading > h5 {
  text-transform: uppercase;
  position: relative;
  margin-bottom: 0;
  padding-bottom: 25px;
}

.uren-sidebar-catagories_area .uren-sidebar_categories.category-module .category-module_heading > h5:before {
  content: "";
  background-color: #ffc400;
  width: 70px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.uren-sidebar-catagories_area .uren-sidebar_categories.category-module .module-body {
  padding: 20px 25px 25px;
}

.uren-sidebar-catagories_area .uren-sidebar_categories.category-module .module-body > .module-list_item > li > a {
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.uren-sidebar-catagories_area .uren-sidebar_categories.category-module .module-body > .module-list_item > li > a.active {
  color: #ffc400;
}

.uren-sidebar-catagories_area .uren-sidebar_categories.category-module .module-body > .module-list_item > li .module-sub-list_item > li {
  padding-left: 20px;
}

.uren-sidebar-catagories_area .uren-sidebar_categories.category-module .module-body > .module-list_item > li .module-sub-list_item > li > a {
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.uren-sidebar-catagories_area .uren-sidebar_categories.category-module .module-body > .module-list_item > li:last-child > a {
  padding-bottom: 0;
}

.sidebar-banner_area {
  padding-bottom: 60px;
}

.sidebar-banner_area .banner-item > a img {
  width: 100%;
}

/* ---Shop Paginatoin Area--- */
.shop-content_wrapper .uren-paginatoin-area {
  padding: 0 0 60px;
}

.shop-content_wrapper .uren-paginatoin-area .product-select-box .product-short > p {
  padding-left: 0;
}

/* ---Shop Toolbar--- */
.shop-toolbar {
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-toolbar {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .shop-toolbar {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-toolbar .product-view-mode {
    text-align: right;
  }
}

@media (max-width: 575px) {
  .shop-toolbar .product-view-mode {
    text-align: center;
  }
}

.shop-toolbar .product-view-mode > a {
  background-color: #333333;
  color: #ffffff;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 1199px) {
  .shop-toolbar .product-view-mode > a.grid-5 {
    display: none;
  }
}

@media (max-width: 991px) {
  .shop-toolbar .product-view-mode > a.grid-4 {
    display: none;
  }
}

@media (max-width: 991px) {
  .shop-toolbar .product-view-mode > a.grid-3 {
    display: none;
  }
}

@media (max-width: 575px) {
  .shop-toolbar .product-view-mode > a.grid-2 {
    display: none;
  }
}

.shop-toolbar .product-view-mode > a.grid-1 {
  display: none;
}

@media (max-width: 575px) {
  .shop-toolbar .product-view-mode > a.grid-1 {
    display: inline-block;
  }
}

.shop-toolbar .product-view-mode > a:hover {
  background-color: #ffc400;
  color: #ffffff !important;
}

.shop-toolbar .product-view-mode > a.active {
  background-color: #ffc400;
}

.shop-toolbar .product-item-selection_area {
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-toolbar .product-item-selection_area {
    padding-top: 30px;
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (max-width: 575px) {
  .shop-toolbar .product-item-selection_area {
    padding-top: 30px;
    justify-content: center;
  }
}

@media (max-width: 479px) {
  .shop-toolbar .product-item-selection_area {
    display: block;
    text-align: center;
  }
}

.shop-toolbar .product-item-selection_area .product-short {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-toolbar .product-item-selection_area .product-short {
    padding-bottom: 15px;
  }
}

@media (max-width: 479px) {
  .shop-toolbar .product-item-selection_area .product-short {
    justify-content: center;
  }
}

.shop-toolbar .product-item-selection_area .product-short > label {
  margin-bottom: 0;
  padding-right: 25px;
}

@media (max-width: 991px) {
  .shop-toolbar .product-item-selection_area .product-short > label {
    padding-right: 10px;
  }
}

.shop-toolbar .product-item-selection_area .product-short .myniceselect.nice-select span {
  height: 40px;
  line-height: 40px;
}

@media (max-width: 767px) {
  .shop-toolbar .product-item-selection_area .product-short .myniceselect.nice-select span {
    padding: 0 35px 0 20px;
  }
}

@media (max-width: 767px) {
  .shop-toolbar .product-item-selection_area .product-short .myniceselect.nice-select .list {
    width: auto;
    right: 0;
    left: auto;
  }
}

.shop-toolbar .product-item-selection_area .product-showing {
  display: flex;
  align-items: center;
  padding-left: 25px;
}

@media (max-width: 479px) {
  .shop-toolbar .product-item-selection_area .product-showing {
    justify-content: center;
    padding: 30px 0 0 0;
  }
}

.shop-toolbar .product-item-selection_area .product-showing > label {
  margin-bottom: 0;
  padding-right: 25px;
}

@media (max-width: 991px) {
  .shop-toolbar .product-item-selection_area .product-showing > label {
    padding-right: 10px;
  }
}

.shop-toolbar .product-item-selection_area .product-showing .myniceselect.nice-select span {
  height: 40px;
  line-height: 40px;
}

@media (max-width: 767px) {
  .shop-toolbar .product-item-selection_area .product-showing .myniceselect.nice-select span {
    padding: 0 35px 0 20px;
  }
}

@media (max-width: 767px) {
  .shop-toolbar .product-item-selection_area .product-showing .myniceselect.nice-select .list {
    width: auto;
    right: 0;
    left: auto;
  }
}

.shop-product-wrap {
  padding: 0 0 30px;
}

.shop-product-wrap.grid .list-slide_item {
  display: none;
}

.shop-product-wrap.grid .product-slide_item {
  display: block;
}

.shop-product-wrap.grid .product-slide_item .inner-slide {
  border: 0;
}

.shop-product-wrap.grid .product-slide_item .inner-slide .single-product {
  border: 1px solid #e5e5e5;
  margin-top: 30px;
}

.shop-product-wrap.grid .product-slide_item .inner-slide .single-product .product-img {
  overflow: visible;
}

.shop-product-wrap.grid .product-slide_item .inner-slide .single-product .product-img img {
  width: 100%;
}

@media (max-width: 1500px) {
  .shop-product-wrap.grid .product-slide_item .inner-slide .single-product .product-img .add-actions > ul > li > a {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}

@media (max-width: 767px) {
  .shop-product-wrap.grid .product-slide_item .inner-slide .single-product .product-img .add-actions > ul > li > a {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}

@media (max-width: 1500px) {
  .shop-product-wrap.grid .product-slide_item .inner-slide .single-product .product-img .add-actions > ul > li > a > i {
    font-size: 14px;
  }
}

.shop-product-wrap.grid.gridview-2 > [class*="col-"] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 575px) {
  .shop-product-wrap.grid.gridview-2 > [class*="col-"] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 1500px) {
  .shop-product-wrap.grid.gridview-2 > [class*="col-"] .product-slide_item .inner-slide .single-product .product-img .add-actions > ul > li > a {
    width: 47px;
    height: 47px;
    line-height: 47px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-product-wrap.grid.gridview-2 > [class*="col-"] .product-slide_item .inner-slide .single-product .product-img .add-actions > ul > li > a {
    width: 34px;
    height: 34px;
    line-height: 34px;
  }
}

.shop-product-wrap.grid.gridview-3 > [class*="col-"] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.333333%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-product-wrap.grid.gridview-3 > [class*="col-"] {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 1500px) {
  .shop-product-wrap.grid.gridview-3 > [class*="col-"] .product-slide_item .inner-slide .single-product .product-img .add-actions > ul > li > a {
    width: 42px;
    height: 42px;
    line-height: 42px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-product-wrap.grid.gridview-3 > [class*="col-"] .product-slide_item .inner-slide .single-product .product-img .add-actions > ul > li > a {
    width: 34px;
    height: 34px;
    line-height: 34px;
  }
}

.shop-product-wrap.grid.gridview-4 > [class*="col-"] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.shop-product-wrap.grid.gridview-5 > [class*="col-"] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.shop-product-wrap.grid.gridview-5 > [class*="col-"] .product-slide_item .inner-slide .single-product .product-img .add-actions > ul > li > a {
  width: 36px;
  height: 36px;
  line-height: 36px;
}

@media (max-width: 1500px) {
  .shop-product-wrap.grid.gridview-5 > [class*="col-"] .product-slide_item .inner-slide .single-product .product-img .add-actions > ul > li > a {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}

.shop-product-wrap.listview > [class*="col-"] {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 100%;
  -moz-box-flex: 1;
  -moz-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  width: 100%;
  max-width: 100%;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item {
  display: block;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product {
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  margin-top: 30px;
  padding: 25px;
  display: flex;
}

@media (max-width: 1199px) {
  .shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product {
    display: block;
  }
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-img {
  position: relative;
  flex-basis: 33.333%;
  width: 33.333%;
}

@media (max-width: 1199px) {
  .shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-img {
    flex-basis: 100%;
    width: 100%;
  }
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-img > a {
  display: block;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-img > a .secondary-img {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: auto;
  height: 100%;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-img:hover .secondary-img {
  opacity: 1;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-content {
  flex-basis: 66.666%;
  width: 66.666%;
  padding-left: 30px;
}

@media (max-width: 1199px) {
  .shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-content {
    flex-basis: 100%;
    width: 100%;
    padding-left: 0;
  }
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-content .product-desc_info > h6 {
  margin-bottom: 0;
  padding-bottom: 10px;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-content .product-desc_info .rating-box {
  padding-bottom: 5px;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-content .product-desc_info .rating-box > ul > li {
  display: inline-block;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-content .product-desc_info .price-box {
  padding-bottom: 5px;
  display: inline-block;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-content .product-desc_info .price-box .new-price {
  font-size: 16px;
  color: #0886cf;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-content .product-desc_info .price-box .new-price.new-price-2 {
  color: #e52e06;
  font-weight: 700;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-content .product-desc_info .price-box .old-price {
  color: #bababa;
  text-decoration: line-through;
  font-size: 14px;
  margin-left: 10px;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-content .product-desc_info .product-short_desc > p {
  margin-bottom: 0;
  padding-bottom: 23px;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-content .add-actions > ul > li {
  display: inline-block;
  padding-left: 5px;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-content .add-actions > ul > li > a {
  border: 1px solid #e5e5e5;
  padding: 10px 20px;
  display: block;
}

@media (max-width: 479px) {
  .shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-content .add-actions > ul > li > a {
    padding: 5px 10px;
  }
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single-product .product-content .add-actions > ul > li > a:hover {
  background-color: #ffc400;
  border-color: #ffc400;
  color: #ffffff !important;
}

.shop-product-wrap.listview > [class*="col-"] .product-slide_item {
  display: none;
}

.shop-product-wrap.listview.listfullwidth > [class*="col-"] {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 50%;
  -moz-box-flex: 1;
  -moz-flex: 1 0 50%;
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  width: 50%;
  max-width: 50%;
}

@media (max-width: 767px) {
  .shop-product-wrap.listview.listfullwidth > [class*="col-"] {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 100%;
    -moz-box-flex: 1;
    -moz-flex: 1 0 100%;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

/*----------------------------------------*/
/*  30 - Pages - Single Product
/*----------------------------------------*/
/* ---Single Product Page--- */
.sp-area {
  padding: 50px 0 0;
}

.sp-area .sp-nav {
  background-color: #ffffff;
  padding-top: 25px;
}

.sp-area .sp-nav .sp-img_slider {
  cursor: move;
}

.sp-area .sp-nav .sp-img_slider .slick-list .slick-slide {
  border: 1px solid #e5e5e5;
}

.sp-area .sp-nav .sp-img_slider .slick-list .slick-slide img {
  width: 100%;
}

.sp-area .sp-nav .sp-img_slider-nav {
  padding: 0 60px;
  margin-top: 30px;
}

.sp-area .sp-nav .sp-img_slider-nav .slick-slide img {
  border: 1px solid #e5e5e5;
  width: 100%;
  opacity: 0.7;
}

.sp-area .sp-nav .sp-img_slider-nav .slick-slide.slick-current img {
  border-color: #ffc400;
  opacity: 1;
}

.sp-area .sp-nav .sp-img_slider-nav .slick-slide:hover img {
  opacity: 1;
}

@media (max-width: 991px) {
  .sp-area .sp-nav .sp-content {
    padding-top: 30px;
  }
}

.sp-area .sp-nav .sp-content .sp-heading > h5 {
  margin-bottom: 0;
  padding-bottom: 20px;
}

@media (max-width: 575px) {
  .sp-area .sp-nav .sp-content .sp-heading > h5 {
    font-size: 18px;
    line-height: 24px;
  }
}

.sp-area .sp-nav .sp-content .rating-box {
  padding: 5px 0 0;
}

.sp-area .sp-nav .sp-content .rating-box > ul > li {
  display: inline-block;
}

.sp-area .sp-nav .sp-content .rating-box > ul > li > i {
  font-size: 14px;
}

.sp-area .sp-nav .sp-content .sp-essential_stuff > ul > li {
  font-weight: 700;
}

.sp-area .sp-nav .sp-content .sp-essential_stuff > ul > li > a {
  font-weight: 400;
  padding-left: 5px;
}

.sp-area .sp-nav .sp-content .sp-essential_stuff > ul > li > a > span {
  color: #999999 !important;
}

.sp-area .sp-nav .sp-content .price-box {
  padding-bottom: 10px;
}

.sp-area .sp-nav .sp-content .price-box .old-price {
  color: #bababa;
  text-decoration: line-through;
  font-size: 14px;
  margin-left: 10px;
}

.sp-area .sp-nav .sp-content .price-box .new-price {
  font-size: 16px;
  color: #0886cf;
}

.sp-area .sp-nav .sp-content .price-box .new-price.new-price-2 {
  color: #e52e06;
  font-weight: 700;
}

.sp-area .sp-nav .sp-content .short-desc > p {
  margin-bottom: 0;
  padding-bottom: 20px;
}

.sp-area .sp-nav .sp-content .countdown-wrap {
  padding: 30px 0 0;
}

.sp-area .sp-nav .sp-content .product-size_box {
  display: flex;
  align-items: center;
  padding-top: 25px;
}

.sp-area .sp-nav .sp-content .product-size_box > span {
  padding-right: 15px;
}

.sp-area .sp-nav .sp-content .product-size_box .myniceselect.nice-select > span {
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
}

.sp-area .sp-nav .sp-content .color-list_area {
  margin-top: 25px;
}

.sp-area .sp-nav .sp-content .quantity {
  padding-top: 30px;
}

.sp-area .sp-nav .sp-content .quantity .cart-plus-minus {
  display: inline-block;
  margin-left: 15px;
}

.sp-area .sp-nav .sp-content .qty-btn_area {
  padding-top: 30px;
}

.sp-area .sp-nav .sp-content .qty-btn_area > ul li {
  display: inline-block;
  padding-left: 5px;
}

.sp-area .sp-nav .sp-content .qty-btn_area > ul li:first-child {
  padding-left: 0;
}

.sp-area .sp-nav .sp-content .qty-btn_area > ul li > a {
  border: 1px solid #e5e5e5;
  display: block;
  padding: 10px 15px;
  color: #333333;
  font-weight: 700;
  text-transform: uppercase;
}

.sp-area .sp-nav .sp-content .qty-btn_area > ul li > a:hover {
  background-color: #ffc400;
  border-color: #ffc400;
  color: #ffffff !important;
}

.sp-area .sp-nav .sp-content .qty-btn_area > ul li > a > i {
  display: block;
}

.sp-area .sp-nav .sp-content .qty-btn_area > ul li > a.qty-cart_btn {
  background-color: #ffc400;
}

.sp-area .sp-nav .sp-content .qty-btn_area > ul li > a.qty-cart_btn:hover {
  background-color: #e52e06;
  border-color: #e52e06;
}

.sp-area .sp-nav .sp-content .uren-tag-line {
  display: flex;
  align-items: center;
  padding-top: 23px;
}

.sp-area .sp-nav .sp-content .uren-tag-line > h6 {
  font-size: 18px;
  margin-bottom: 0;
}

.sp-area .sp-nav .sp-content .uren-tag-line > a {
  padding-left: 10px;
  display: block;
}

@media (max-width: 575px) {
  .sp-area .sp-nav .sp-content .uren-tag-line > a {
    font-size: 14px;
  }
}

.sp-area .sp-nav .sp-content .uren-social_link {
  padding-top: 25px;
  display: flex;
  align-items: center;
}

/* ---Single Product Group Page--- */
.sp-group_area .sp-nav .sp-content .choose-group-product {
  padding-top: 30px;
}

.sp-group_area .sp-nav .sp-content .choose-group-product .cart-table table {
  margin-bottom: 0;
}

.sp-group_area .sp-nav .sp-content .choose-group-product .cart-table table tbody tr td {
  vertical-align: middle;
  text-align: center;
}

/* ---Single Produc Affiliate Page--- */
.sp-affiliate_area .sp-content .qty-btn_area {
  display: inline-block;
  padding-top: 25px !important;
}

.sp-affiliate_area .sp-content .qty-btn_area a.qty-buy_btn {
  background-color: #333333;
  color: #ffffff;
  padding: 15px 30px;
  display: block;
}

.sp-affiliate_area .sp-content .qty-btn_area a.qty-buy_btn:hover {
  background-color: #ffc400;
  color: #ffffff !important;
}

/* ---Single Product Slider Area--- */
.sp-slider_area {
  padding-top: 60px;
}

.sp-slider_area .sp-nav {
  padding: 30px 0 0;
}

.sp-slider_area .sp-nav .sp-slider {
  border: 1px solid #e5e5e5;
  overflow: hidden;
}

.sp-slider_area .sp-nav .sp-slider .product-slide_item .inner-slide {
  border-right: 0;
}

.sp-slider_area .sp-nav .sp-slider .product-slide_item .inner-slide .single-product .product-img {
  position: relative;
  overflow: hidden;
}

.sp-slider_area .sp-nav .sp-slider .product-slide_item .inner-slide .single-product .product-img > a {
  display: block;
}

.sp-slider_area .sp-nav .sp-slider .product-slide_item .inner-slide .single-product .product-img > a .secondary-img {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}

.sp-slider_area .sp-nav .sp-slider .product-slide_item .inner-slide .single-product .product-img:hover .secondary-img {
  opacity: 1;
}

.sp-slider_area .sp-nav .sp-slider .slick-list .slick-slide {
  border-right: 1px solid #e5e5e5;
}

.sp-slider_area .sp-nav .sp-slider .slick-list .slick-slide.last-active {
  border-right: 0;
}

.sp-slider_area .sp-nav .sp-content {
  padding-top: 25px !important;
  float: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.sp-slider_area .sp-nav .sp-content .product-size_box {
  justify-content: center;
}

.sp-slider_area .sp-nav .sp-content .uren-tag-line {
  justify-content: center;
}

@media (max-width: 575px) {
  .sp-slider_area .sp-nav .sp-content .uren-tag-line {
    margin: 0 -30px;
  }
}

.sp-slider_area .sp-nav .sp-content .uren-social_link {
  justify-content: center;
}

@media (max-width: 479px) {
  .sp-slider_area .sp-nav .sp-content .uren-social_link > ul > li > a {
    width: 35px;
  }
}

/* ---Single Product Gallery--- */
.sp-gallery_area .sp-nav {
  padding-top: 30px;
}

.sp-gallery_area .sp-nav .sp-gallery .lg-image {
  padding: 5px;
  border: 1px solid #e5e5e5;
}

.sp-gallery_area .sp-nav .sp-gallery .lg-image:hover {
  border-color: #ffc400;
}

@media (max-width: 991px) {
  .sp-gallery_area .sp-nav .sp-gallery .lg-image {
    margin-bottom: 30px;
  }
}

.sp-gallery_area .sp-nav .sp-gallery > .row:first-child {
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .sp-gallery_area .sp-nav .sp-gallery > .row:first-child {
    margin-bottom: 0;
  }
}

/* ---Single Product Gallery Right--- */
.sp-gallery-right_area .sp-nav {
  padding: 30px 0;
}

/* ---Single Product Tab Style--- */
.sp-tab-style_left .sp-img_area {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.sp-tab-style_left .sp-img_area .sp-img_slider {
  flex-basis: 80%;
  max-width: 80%;
  padding-left: 30px;
}

.sp-tab-style_left .sp-img_area .sp-img_slider-nav {
  flex-basis: 20%;
  max-width: 20%;
  padding: 0;
  margin-top: 0;
}

.sp-tab-style_left.sp-tab-style_right .sp-img_area {
  flex-direction: unset;
}

.sp-tab-style_left.sp-tab-style_right .sp-img_area .sp-img_slider {
  padding: 0 30px 0 0;
}

/* ---Single Product Sticky--- */
.sp-sticky_area {
  padding: 80px 0 0;
}

.sp-sticky_area .sp-nav {
  padding: 0;
}

.sp-sticky_area .sp-sticky_gallery {
  padding-left: 0 !important;
}

.sp-sticky_area .sp-sticky_gallery > .row:first-child {
  margin-bottom: 0 !important;
}

.sp-sticky_area .sp-sticky_gallery > .row [class*="col-"] .lg-image {
  margin-bottom: 30px;
}

.sp-sticky_area .sp-sticky_gallery > .row:last-child [class*="col-"]:last-child .lg-image {
  margin-bottom: 0;
}

.sp-product-tab_area {
  padding-bottom: 0;
  margin-bottom: -10px;
}

.sp-product-tab_area .sp-product-tab_nav {
  background-color: #ffffff;
  padding-top: 30px;
}

.sp-product-tab_area .sp-product-tab_nav .product-tab {
  background-color: #f4f4f4;
  padding: 25px 0 25px;
}

.sp-product-tab_area .sp-product-tab_nav .product-tab .product-menu {
  padding-bottom: 0 !important;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content {
  border: 1px solid #e5e5e5;
  padding: 30px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .product-description {
  margin: -5px 0 -5px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .product-description > ul > li {
  padding-top: 15px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .product-description > ul > li:first-child {
  padding-top: 0;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .product-description > ul > li strong {
  display: block;
  font-size: 18px;
  padding-bottom: 10px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .table-striped {
  margin-bottom: 25px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .table-striped > tbody tr:nth-child(2) td > p {
  margin-bottom: 0;
  padding-bottom: 20px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .table-striped > tbody tr:nth-child(2) td .rating-box > ul > li {
  display: inline-block;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .table-striped > tbody tr td {
  padding: 20px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal h2 {
  margin-bottom: 0;
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  .sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal h2 {
    font-size: 25px;
  }
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group {
  margin-bottom: 0;
  padding-bottom: 15px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group.second-child {
  padding-bottom: 10px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group.last-child {
  padding-bottom: 0;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .review-input,
.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .review-textarea {
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 30px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .review-input.review-textarea,
.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .review-textarea.review-textarea {
  height: 120px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group label {
  margin-bottom: 0;
  padding-bottom: 18px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .help-block {
  padding-top: 18px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .uren-btn-ps_right {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 479px) {
  .sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .uren-btn-ps_right {
    justify-content: flex-start;
    padding-top: 20px;
  }
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .your-opinion span .br-wrapper .br-widget a {
  font-size: 16px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .your-opinion span .br-wrapper .br-widget a.br-active:after {
  color: #ffc400;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .your-opinion span .br-wrapper .br-widget a.br-selected:after {
  color: #ffc400;
}

/* ---Single Product Slider Area--- */
.sp-product-slider_area {
  padding: 40px 0 60px;
}

/*----------------------------------------*/
/*  31 - Pages - Blog
/*----------------------------------------*/
.uren-blog_area {
  padding: 80px 0;
}

@media (max-width: 991px) {
  .uren-blog_area {
    padding: 80px 0 75px;
  }
}

.uren-blog_area .blog-img {
  position: relative;
}

.uren-blog_area .blog-img > a > img {
  width: 100%;
}

.uren-blog_area .blog-img > span.post-date {
  background-color: #e52e06;
  color: #ffffff;
  position: absolute;
  height: 30px;
  line-height: 30px;
  width: 125px;
  text-align: center;
  font-weight: 700;
  top: 40px;
  left: -35px;
  transform: rotate(90deg);
}

.uren-blog_area .blog-content {
  padding-top: 25px;
}

.uren-blog_area .blog-content > h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 15px;
  line-height: 24px;
}

.uren-blog_area .blog-content > h3 > a {
  color: #222222;
}

.uren-blog_area.column-two_area .blog-item_wrap [class*="col-"]:not(:nth-child(-n+2)) .blog-item {
  margin-top: 30px;
}

@media (max-width: 991px) {
  .uren-blog_area.column-two_area .blog-item_wrap [class*="col-"]:not(:first-child) .blog-item {
    margin-top: 30px;
  }
}

.uren-blog_area.column-two_area .single-blog_slider .slick-list {
  margin-left: -15px;
  margin-right: -15px;
}

.uren-blog_area.column-two_area .single-blog_slider .slick-list .slick-slide {
  padding-left: 15px;
  padding-right: 15px;
}

.uren-blog_area.grid-view_area .blog-item_wrap [class*="col-"]:not(:nth-child(-n+3)) .blog-item {
  margin-top: 30px !important;
}

@media (max-width: 991px) {
  .uren-blog_area.grid-view_area .blog-item_wrap [class*="col-"]:not(:first-child) .blog-item {
    margin-top: 30px;
  }
}

.uren-blog_area.grid-view_area .single-blog_slider .slick-list {
  margin-left: -15px;
  margin-right: -15px;
}

.uren-blog_area.grid-view_area .single-blog_slider .slick-list .slick-slide {
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .uren-blog_area.blog-with_sidebar .blog-item_wrap [class*="col-"]:not(:nth-child(-n+2)) .blog-item {
    margin-top: 30px !important;
  }
}

.uren-blog_area.list-view_area .list-item_wrap .blog-item .blog-content {
  padding-top: 0;
}

@media (max-width: 991px) {
  .uren-blog_area.list-view_area .list-item_wrap .blog-item .blog-content {
    padding-top: 30px;
  }
}

.uren-blog_area.list-view_area .list-item_wrap .blog-item .blog-content > h3 {
  line-height: 24px;
}

.uren-blog_area.list-view_area .list-item_wrap [class*="col-"]:not(:nth-child(-n+2)) .blog-item {
  margin-top: 30px;
}

@media (max-width: 991px) {
  .uren-blog_area.list-view_area .list-item_wrap [class*="col-"]:not(:first-child) .blog-item {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .uren-blog_area.list-view_area.blog-with_sidebar-2 .list-item_wrap [class*="col-"]:not(:first-child) .blog-item {
    margin-top: 30px !important;
  }
}

.uren-blog_area .single-blog_slider .slick-list {
  margin-left: -15px;
  margin-right: -15px;
}

.uren-blog_area .single-blog_slider .slick-list .slick-slide {
  padding-left: 15px;
  padding-right: 15px;
}

/* ---Blog Sidebar--- */
.uren-blog-sidebar-wrapper {
  /* --Search Form--- */
}

@media (max-width: 991px) {
  .uren-blog-sidebar-wrapper {
    padding-top: 75px;
  }
}

.uren-blog-sidebar-wrapper .search-form_area form.search-form {
  position: relative;
}

.uren-blog-sidebar-wrapper .search-form_area form.search-form .search-field {
  border: 1px solid #e5e5e5;
  padding: 0 40px 0 10px;
  font-size: 14px;
  height: 40px;
  width: 100%;
  text-transform: capitalize;
}

.uren-blog-sidebar-wrapper .search-form_area form.search-form .search-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.uren-blog-sidebar-wrapper .search-form_area form.search-form .search-btn:hover {
  color: #ffc400;
}

.uren-blog-sidebar-wrapper .search-form_area form.search-form .search-btn > i {
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 43px;
  display: block;
  text-align: center;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar {
  padding-top: 30px;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar:first-child {
  padding-top: 0;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar:last-child {
  padding-top: 20px;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .uren-blog-sidebar-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
  margin-bottom: 55px;
  text-align: center;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .uren-blog-sidebar-title:before {
  background-color: #e5e5e5;
  content: "";
  width: 100%;
  height: 1px;
  bottom: -26px;
  left: 0;
  position: absolute;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .uren-blog-archive li {
  margin-bottom: 10px;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .uren-blog-archive li a {
  font-size: 14px;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .uren-blog-archive li:last-child {
  margin-bottom: 0;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .recent-post {
  display: flex;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .recent-post .recent-post_thumb {
  width: 65px;
  height: 100%;
  margin-bottom: 30px;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .recent-post .recent-post_desc {
  width: calc(100% - 65px);
  padding-left: 15px;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .recent-post .recent-post_desc span {
  display: block;
  text-transform: capitalize;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .recent-post .recent-post_desc span a:hover {
  color: #ea3a3c;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .recent-post .recent-post_desc .post-date {
  font-weight: 400;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .recent-comment {
  display: flex;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .recent-comment .user-img {
  width: 65px;
  height: 100%;
  margin-bottom: 30px;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .recent-comment .user-img > img {
  border-radius: 100%;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .recent-comment .user-info {
  width: calc(100% - 65px);
  padding-left: 15px;
}

.uren-blog-sidebar-wrapper .uren-blog-sidebar .recent-comment .user-info > span {
  display: block;
  text-transform: capitalize;
}

/* ---Uren's Blog Details--- */
.uren-blog-details .blog-item .blog-content {
  background-color: transparent;
  padding: 25px 0;
}

.uren-blog-details .uren-blog-blockquote {
  padding: 0 0 0 40px;
}

@media (max-width: 991px) {
  .uren-blog-details .uren-blog-blockquote {
    padding: 0;
  }
}

.uren-blog-details .uren-blog-blockquote blockquote {
  border: 1px solid #e5e5e5;
  padding: 40px;
  position: relative;
  margin: 0 0 20px;
  font-style: italic;
  background: #f4f4f4;
}

.uren-blog-details .uren-blog-blockquote blockquote p:before {
  content: "";
  background: #ffc400;
  position: absolute;
  width: 5px;
  height: 100%;
  top: 0;
  left: 0;
}

.uren-blog-details .uren-tag-line {
  padding: 25px 0 25px;
  margin-top: 25px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.uren-blog-details .uren-tag-line h4 {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  display: inline-block;
  padding-right: 5px;
}

.uren-blog-details .uren-tag-line a {
  font-style: italic;
  text-transform: capitalize;
  font-size: 16px;
}

.uren-blog-details .uren-tag-line a:hover {
  color: #ffc400;
}

.uren-blog-details .uren-social_link {
  padding-top: 30px;
  text-align: center;
}

@media (max-width: 479px) {
  .uren-blog-details .uren-social_link {
    text-align: left;
  }
}

.uren-blog-details .uren-comment-section {
  padding-top: 28px;
}

.uren-blog-details .uren-comment-section h3 {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 25px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.uren-blog-details .uren-comment-section ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 30px 23px;
  margin-bottom: 30px;
  border: 1px solid #e5e5e5;
}

@media (max-width: 479px) {
  .uren-blog-details .uren-comment-section ul li {
    display: block;
  }
}

.uren-blog-details .uren-comment-section ul li .author-avatar {
  -ms-flex-preferred-size: 70px;
  flex-basis: 70px;
}

@media (max-width: 479px) {
  .uren-blog-details .uren-comment-section ul li .author-avatar {
    text-align: center;
    padding-bottom: 20px;
  }
}

.uren-blog-details .uren-comment-section ul li .comment-body {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  padding-left: 30px;
}

@media (max-width: 479px) {
  .uren-blog-details .uren-comment-section ul li .comment-body {
    padding-left: 0;
  }
}

.uren-blog-details .uren-comment-section ul li .comment-body h5 {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 13px;
  margin-bottom: 0;
  text-transform: capitalize;
}

@media (max-width: 479px) {
  .uren-blog-details .uren-comment-section ul li .comment-body h5 {
    padding-bottom: 18px;
    overflow: hidden;
    line-height: 26px;
  }
}

.uren-blog-details .uren-comment-section ul li .comment-body p {
  font-size: 14px;
  max-width: 80%;
  margin-bottom: 0;
}

@media (max-width: 479px) {
  .uren-blog-details .uren-comment-section ul li .comment-body p {
    max-width: 100%;
  }
}

.uren-blog-details .uren-comment-section ul li .comment-body .comment-post-date {
  padding-bottom: 10px;
}

@media (max-width: 479px) {
  .uren-blog-details .uren-comment-section ul li .comment-body .comment-post-date {
    padding-bottom: 15px;
  }
}

.uren-blog-details .uren-comment-section ul li .comment-body .reply-btn {
  float: right;
}

.uren-blog-details .uren-comment-section ul li .comment-body .reply-btn a {
  color: #ffc400;
  font-size: 12px;
  display: inline-block;
  width: 60px;
  height: 25px;
  line-height: 21px;
  text-align: center;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  text-transform: capitalize;
  border: 1px solid #ffc400;
}

.uren-blog-details .uren-comment-section ul li .comment-body .reply-btn a:hover {
  color: #ffffff !important;
  background-color: #ffc400;
}

.uren-blog-details .uren-comment-section ul .comment-children {
  margin-left: 40px;
}

@media (max-width: 767px) {
  .uren-blog-details .uren-comment-section ul .comment-children {
    margin-left: 15px;
  }
}

.uren-blog-details .uren-blog-comment-wrapper h3 {
  border-bottom: 1px solid #e5e5e5;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  padding: 0 0 26px;
  margin-bottom: 0;
}

.uren-blog-details .uren-blog-comment-wrapper p {
  padding: 20px 0 0;
  font-size: 14px;
}

.uren-blog-details .uren-blog-comment-wrapper form .comment-post-box label {
  font-weight: 600;
  margin-bottom: 25px;
  display: block;
  text-transform: capitalize;
}

.uren-blog-details .uren-blog-comment-wrapper form .comment-post-box .coment-field {
  background: #f4f4f4;
  border: none;
  padding: 8px 10px;
  width: 100%;
}

.uren-blog-details .uren-blog-comment-wrapper form .comment-post-box textarea {
  height: 130px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
  background: #f4f4f4;
  border: none;
  font-size: 14px;
}

.uren-blog-details .uren-blog-comment-wrapper form .comment-post-box .comment-btn_wrap {
  padding-top: 30px;
}

@media (max-width: 767px) {
  .uren-blog-details .uren-blog-comment-wrapper form .comment-post-box [class*="col-"]:nth-child(-n + 3) .coment-field {
    margin-bottom: 30px;
  }
}

/*----------------------------------------*/
/*  32 - Pages - My Account
/*----------------------------------------*/
.account-page-area {
  padding: 80px 0;
}

.account-page-area .myaccount-tab-trigger {
  display: block;
}

@media (max-width: 991px) {
  .account-page-area .myaccount-tab-trigger {
    padding-bottom: 30px;
  }
}

.account-page-area .myaccount-tab-trigger li {
  display: block;
}

.account-page-area .myaccount-tab-trigger li:first-child {
  margin-top: -1px;
}

.account-page-area .myaccount-tab-trigger li a {
  display: block;
  background: #999999;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 20px;
}

.account-page-area .myaccount-tab-trigger li a.active {
  background: #ffc400;
  color: #ffffff;
}

.account-page-area .myaccount-tab-trigger li a.active:hover {
  color: #ffffff !important;
}

.account-page-area .myaccount-tab-trigger li a:hover {
  color: #ffc400;
}

.myaccount-tab-content {
  border: 1px solid #e5e5e5;
  padding: 30px;
}

.myaccount-dashboard p {
  margin-bottom: 20px;
}

.myaccount-dashboard p:last-child {
  margin-bottom: 0;
}

.myaccount-orders .table {
  margin-bottom: 0;
}

.myaccount-orders .table .account-order-id:hover {
  color: #ffc400;
}

.myaccount-orders .table.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.myaccount-orders .table td,
.myaccount-orders .table th {
  vertical-align: middle;
  text-align: center;
}

@media (max-width: 767px) {
  .myaccount-orders .table td,
  .myaccount-orders .table th {
    min-width: 180px;
  }
}

.myaccount-orders .small-title {
  margin-bottom: 15px;
}

/* ---My Account Form Style--- */
.uren-form,
.uren-form-boxed {
  padding: 30px;
  border-radius: 0;
  border: 1px solid #e5e5e5;
}

.uren-form .uren-form-inner,
.uren-form-boxed .uren-form-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: -20px;
}

@media (max-width: 479px) {
  .uren-form .uren-form-inner,
  .uren-form-boxed .uren-form-inner {
    display: block;
  }
}

.uren-form .uren-form-inner .single-input,
.uren-form-boxed .uren-form-inner .single-input {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 20px;
}

@media (max-width: 479px) {
  .uren-form .uren-form-inner .single-input,
  .uren-form-boxed .uren-form-inner .single-input {
    max-width: 100% !important;
  }
}

.uren-form .uren-form-inner .single-input.single-input-half,
.uren-form-boxed .uren-form-inner .single-input.single-input-half {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 15px);
  flex: 0 0 calc(50% - 15px);
  max-width: calc(50% - 15px);
}

.uren-form .uren-form-inner .single-input label,
.uren-form-boxed .uren-form-inner .single-input label {
  margin-bottom: 8px;
  display: block;
}

.uren-form .uren-form-inner .single-input input,
.uren-form .uren-form-inner .single-input select,
.uren-form .uren-form-inner .single-input textarea,
.uren-form-boxed .uren-form-inner .single-input input,
.uren-form-boxed .uren-form-inner .single-input select,
.uren-form-boxed .uren-form-inner .single-input textarea {
  padding: 0 15px;
  border: 1px solid #e5e5e5;
  height: 40px;
  width: 100%;
}

.uren-form .uren-form-inner .single-input input:first-child,
.uren-form-boxed .uren-form-inner .single-input input:first-child {
  margin-top: 20px;
}

.uren-form .uren-form-inner .single-input .checkbox-input,
.uren-form-boxed .uren-form-inner .single-input .checkbox-input {
  display: inline-block;
}

.uren-form .uren-form-inner .single-input .checkbox-input label,
.uren-form-boxed .uren-form-inner .single-input .checkbox-input label {
  display: inline-block;
}

.uren-form .uren-form-inner a,
.uren-form-boxed .uren-form-inner a {
  font-size: 13px;
}

.uren-form .uren-form-inner a:hover,
.uren-form-boxed .uren-form-inner a:hover {
  color: #ffc400;
}

/*----------------------------------------*/
/*  33 - Pages - Login | Register
/*----------------------------------------*/
/* ---JB's Login Register Area--- */
.uren-login-register_area {
  padding: 80px 0;
}

@media (max-width: 991px) {
  [class*="col-"]:first-child form .login-form {
    margin-bottom: 30px;
  }
}

.login-form {
  background-color: #ffffff;
  padding: 30px;
  -webkit-box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.1);
}

.login-form .login-title {
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.login-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.login-form input {
  width: 100%;
  background-color: transparent;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  color: #888888;
  margin-bottom: 15px;
}

.login-form input[type="checkbox"] {
  width: auto;
}

.login-form .check-box {
  float: left;
  margin-right: 70px;
}

.login-form .check-box:last-child {
  margin-right: 0;
}

.login-form .check-box input[type="checkbox"] {
  display: none;
}

.login-form .check-box input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #888888;
  margin: 0;
}

.login-form .check-box input[type="checkbox"] + label:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #e5e5e5;
  content: "";
}

.login-form .check-box input[type="checkbox"] + label:after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "\f00c";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  opacity: 0;
  width: 20px;
  text-align: center;
}

.login-form .check-box input[type="checkbox"]:checked + label:before {
  border: 2px solid #888888;
}

.login-form .check-box input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

@media (max-width: 767px) {
  .forgotton-password_info {
    padding-top: 15px;
  }
}

/*-- Place Order --*/
.uren-login_btn,
.uren-register_btn {
  background-color: #999999;
  color: #ffffff;
  display: block;
  margin-top: 30px;
  width: 140px;
  border-radius: 0;
  height: 40px;
  line-height: 40px;
  border: 0;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .uren-login_btn,
  .uren-register_btn {
    margin-top: 25px;
  }
}

.uren-login_btn:hover,
.uren-register_btn:hover {
  background: #ffc400;
}

.uren-register_btn {
  margin-top: 15px;
}

/*----------------------------------------*/
/*  34 - Pages - Wishlist
/*----------------------------------------*/
.uren-wishlist_area {
  padding: 80px 0;
}

.table-content table {
  background: #ffffff none repeat scroll 0 0;
  border-color: #e5e5e5;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}

.table-content table th,
.table-content table td {
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}

.table-content table th {
  border-top: medium none;
  font-size: 15px;
  text-transform: capitalize;
  padding: 20px 10px;
  text-align: center;
  font-weight: 500;
  vertical-align: middle;
}

.table-content table td {
  border-top: medium none;
  padding: 18px 10px;
  vertical-align: middle;
  font-size: 13px;
}

@media (max-width: 767px) {
  .table-content table td {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .table-content table td:nth-child(3) {
    padding: 32.1px 10px;
    display: block;
    width: 180px;
  }
}

.table-content table td.uren-product_remove {
  font-size: 20px;
}

.table-content table td.uren-product_remove > a:hover i {
  color: #ffc400;
}

.table-content table td.uren-product_remove > a > i {
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.table-content table td.uren-product-name {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.table-content table td.uren-product-price {
  font-size: 16px;
  font-weight: 700;
}

.table-content table td.uren-product-stock-status span.in-stock, .table-content table td.uren-product-stock-status span.out-stock {
  color: #ffc400;
  font-size: 12px;
  text-transform: capitalize;
}

.table-content table td.uren-product-stock-status span.out-stock {
  color: #ea3a3c;
}

.table-content table td.uren-cart_btn a {
  font-size: 14px;
  text-transform: uppercase;
  background: #999999;
  color: #ffffff;
  height: 40px;
  line-height: 43px;
  width: 130px;
  font-weight: 700;
  display: inline-block;
}

.table-content table td.uren-cart_btn a:hover {
  background: #ffc400;
  color: #ffffff !important;
}

/*----------------------------------------*/
/*  35 - Pages - Cart
/*----------------------------------------*/
.uren-cart-area {
  padding: 80px 0;
}

.table-content table td.product-subtotal {
  font-size: 16px;
  font-weight: 700;
}

.table-content table td.quantity .cart-plus-minus {
  margin: 0 auto;
}

@media (max-width: 767px) {
  .uren-cart-area .table-content table td:nth-child(3) {
    padding: 45.1px 10px;
  }
}

.coupon-all {
  margin-top: 30px;
}

.coupon-all .coupon {
  float: left;
}

.coupon-all .coupon input {
  background-color: transparent;
  border: 1px solid #e5e5e5;
  font-size: 13px;
  border-radius: 0;
  height: 42px;
  width: 120px;
  padding: 0 10px;
}

.coupon-all .coupon input.button,
.coupon-all .coupon2 input.button {
  background-color: #999999;
  border: 0 none;
  border-radius: 2px;
  color: #ffffff;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  height: 42px;
  letter-spacing: 1px;
  line-height: 42px;
  padding: 0 25px;
  text-transform: uppercase;
  width: inherit;
}

.coupon-all .coupon input.button:hover,
.coupon-all .coupon2 input.button:hover {
  background: #ffc400;
}

.coupon-all .coupon.coupon2,
.coupon-all .coupon2.coupon2 {
  float: right;
}

@media (max-width: 479px) {
  .coupon-all .coupon.coupon2,
  .coupon-all .coupon2.coupon2 {
    float: left;
    padding-top: 30px;
  }
}

.cart-page-total {
  padding-top: 30px;
}

.cart-page-total > h2 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.cart-page-total > ul {
  border: 1px solid #e5e5e5;
}

.cart-page-total > ul li {
  list-style: none;
  font-size: 15px;
  padding: 10px 30px;
  border-bottom: 1px solid #e5e5e5;
  font-weight: 700;
}

.cart-page-total > ul li:last-child {
  border-bottom: 0;
}

.cart-page-total > ul li span {
  float: right;
}

.cart-page-total a {
  background-color: #999999;
  border: 1px solid #e5e5e5;
  color: #ffffff;
  display: inline-block;
  margin-top: 30px;
  padding: 10px 20px;
  text-transform: capitalize;
}

.cart-page-total a:hover {
  background: #ffc400;
  border-color: #ffc400;
  color: #ffffff !important;
}

/*----------------------------------------*/
/*  36 - Pages - Checkout
/*----------------------------------------*/
.checkout-area {
  padding: 80px 0 75px;
}

.coupon-accordion h3 {
  background-color: #f4f4f4;
  border-top: 3px solid #ffc400;
  font-size: 14px;
  font-weight: 400;
  list-style: outside none none;
  margin: 0 0 30px;
  padding: 16px 32px 16px 56px;
  position: relative;
  width: auto;
  text-transform: none;
}

@media (max-width: 479px) {
  .coupon-accordion h3 {
    line-height: 24px;
  }
}

.coupon-accordion h3:before {
  color: #ffc400;
  content: "\f133";
  display: inline-block;
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  left: 24px;
  position: absolute;
  top: 16px;
}

.coupon-accordion span {
  cursor: pointer;
  text-transform: none;
}

.coupon-accordion span:hover {
  color: #ffc400;
}

.coupon-accordion .coupon-content {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  display: none;
  padding: 20px;
}

.coupon-accordion .coupon-content .coupon-info p {
  margin-bottom: 0;
}

.coupon-accordion .coupon-content .coupon-info p.form-row-first label, .coupon-accordion .coupon-content .coupon-info p.form-row-last label {
  display: block;
}

.coupon-accordion .coupon-content .coupon-info p.form-row-first label span.required, .coupon-accordion .coupon-content .coupon-info p.form-row-last label span.required {
  color: #ff0000;
  font-weight: 700;
}

.coupon-accordion .coupon-content .coupon-info p.form-row-first input, .coupon-accordion .coupon-content .coupon-info p.form-row-last input {
  border: 1px solid #e5e5e5;
  height: 36px;
  margin: 0 0 14px;
  border-radius: 0;
  max-width: 100%;
  padding: 0 0 0 10px;
  width: 370px;
  background-color: transparent;
}

.coupon-accordion .coupon-content .coupon-info p.form-row input[type="submit"] {
  background: #999999 none repeat scroll 0 0;
  border: medium none;
  border-radius: 0;
  box-shadow: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  float: left;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  padding: 0 30px;
  text-shadow: none;
  text-transform: uppercase;
  width: inherit;
}

.coupon-accordion .coupon-content .coupon-info p.form-row input[type="submit"]:hover {
  background: #ffc400;
}

.coupon-accordion .coupon-content .coupon-info p.form-row input[type="checkbox"] {
  height: inherit;
  position: relative;
  top: 2px;
  width: inherit;
}

.coupon-accordion .coupon-content .coupon-info p.form-row label {
  margin-top: 7px;
}

.coupon-accordion .coupon-content .coupon-info p.checkout-coupon input[type="submit"] {
  background: #ffc400;
}

.coupon-accordion .coupon-content .coupon-info p.lost-password {
  margin-top: 15px;
}

.coupon-accordion .coupon-content .coupon-info p.lost-password a:hover {
  color: #ffc400;
}

.coupon-checkout-content {
  margin-bottom: 30px;
  display: none;
}

.coupon-checkout-content .coupon-info .checkout-coupon {
  margin-bottom: 0;
}

.coupon-checkout-content .coupon-info .checkout-coupon input[type="text"] {
  background-color: transparent;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 36px;
  padding-left: 10px;
  width: 170px;
}

.coupon-checkout-content .coupon-info .checkout-coupon input[type="submit"] {
  background: #999999;
  border: medium none;
  border-radius: 0;
  color: #ffffff;
  height: 36px;
  cursor: pointer;
  margin-left: 6px;
  padding: 5px 10px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: inherit;
}

.coupon-checkout-content .coupon-info .checkout-coupon input[type="submit"]:hover {
  background: #ffc400;
}

@media (max-width: 479px) {
  .coupon-checkout-content .coupon-info .checkout-coupon .coupon-inner_btn {
    margin: 28px 0 0 !important;
  }
}

.checkbox-form h3 {
  border-bottom: 1px solid #e5e5e5;
  font-size: 25px;
  margin: 0 0 25px;
  padding-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
}

@media (max-width: 479px) {
  .checkbox-form h3 {
    font-size: 18px;
  }
}

.checkbox-form .country-select {
  margin-bottom: 25px;
}

.checkbox-form .country-select .myniceselect.nice-select span {
  height: 40px;
  line-height: 40px;
  display: inline-block;
  width: 100%;
  padding: 0 0 0 15px;
}

.checkbox-form .checkout-form-list {
  margin-bottom: 30px;
}

.checkbox-form .checkout-form-list input[type="text"],
.checkbox-form .checkout-form-list input[type="password"],
.checkbox-form .checkout-form-list input[type="email"] {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 42px;
  width: 100%;
  padding: 0 0 0 10px;
}

.checkbox-form .checkout-form-list input[type="checkbox"] {
  display: inline-block;
  height: inherit;
  margin-right: 10px;
  position: relative;
  top: 2px;
  width: inherit;
}

.checkbox-form .checkout-form-list.create-acc {
  margin-bottom: 15px !important;
}

.checkbox-form .checkout-form-list.create-acc label {
  display: inline-block;
}

.checkbox-form .country-select label, .checkbox-form.checkout-form-list label {
  margin: 0 0 5px;
  display: block;
}

.checkbox-form .country-select label span.required, .checkbox-form.checkout-form-list label span.required {
  color: #ffc400;
  display: inline-block;
  font-size: 24px;
  line-height: 16px;
  position: relative;
  top: 5px;
}

.different-address h3 label {
  display: inline-block;
  margin-right: 20px;
  font-size: 25px;
  margin-bottom: 10px;
}

@media (max-width: 479px) {
  .different-address h3 label {
    font-size: 15px;
  }
}

.different-address h3 input {
  height: inherit;
  line-height: normal;
  margin: 4px 0 0;
  position: relative;
  top: 0;
  width: auto;
}

.order-notes .checkout-form-list-2 {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .order-notes .checkout-form-list-2 {
    margin-bottom: 30px;
  }
}

.order-notes textarea {
  background-color: transparent;
  border: 1px solid #e5e5e5;
  height: 90px;
  padding: 15px;
  width: 100%;
}

.create-account,
#ship-box-info {
  display: none;
}

.your-order {
  background: #f4f4f4;
  padding: 30px 40px 30px;
}

.your-order h3 {
  border-bottom: 1px solid #e5e5e5;
  font-size: 25px;
  padding-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
}

@media (max-width: 479px) {
  .your-order h3 {
    font-size: 18px;
  }
}

.your-order .your-order-table table {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  width: 100%;
}

.your-order .your-order-table table thead {
  background-color: #f4f4f4;
}

.your-order .your-order-table table thead tr th {
  border-top: medium none;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  width: 250px;
}

.your-order .your-order-table table thead tr th,
.your-order .your-order-table table thead tr td {
  border-bottom: 1px solid #e5e5e5;
  border-right: medium none;
  font-size: 14px;
  padding: 15px 0;
  text-align: center;
}

.your-order .your-order-table table .cart_item:hover {
  background: #f4f4f4;
}

.your-order .your-order-table table .order-total td {
  border-bottom: medium none;
}

.your-order .your-order-table table .order-total td span {
  font-size: 20px;
}

.your-order .your-order-table table .order-total th {
  border-bottom: medium none;
  font-size: 18px;
}

.payment-method {
  margin-top: 20px;
}

.payment-method .payment-accordion .card {
  border: 0;
  border-radius: 0;
  background: #f4f4f4;
}

.payment-method .payment-accordion .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: #f4f4f4;
  border-bottom: 0;
}

.payment-method .payment-accordion .card-body {
  padding: 0;
}

.payment-method .payment-accordion .panel.panel-default {
  margin-bottom: 20px;
}

.payment-method .payment-accordion .panel-title {
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}

.payment-method .payment-accordion .panel-title a:hover {
  color: #ffc400 !important;
}

.order-button-payment input {
  background: #999999;
  border: medium none;
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  height: 50px;
  margin: 20px 0 0;
  padding: 0;
  text-transform: uppercase;
  width: 100%;
  border: 1px solid transparent;
  cursor: pointer;
}

.order-button-payment input:hover {
  background: #ffc400;
}

/*----------------------------------------*/
/*  37 - Pages - Compare
/*----------------------------------------*/
.compare-area {
  padding: 80px 0;
}

.compare-table .table thead th {
  border-width: 1px;
  padding: 15px 10px;
}

.compare-table .table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.compare-table .table tbody tr td,
.compare-table .table tbody tr th {
  vertical-align: middle;
  text-align: center;
}

.compare-table .table tbody tr th {
  min-width: 200px;
}

.compare-table .table tbody tr td {
  min-width: 250px;
}

.compare-table .table tbody tr td .compare-pdoduct-image {
  max-width: 200px;
  display: inline-block;
  padding: 20px 0;
}

.compare-table .table tbody tr td .compare-pdoduct-image a {
  display: inline-block;
}

.compare-table .table tbody tr td .compare-pdoduct-image .uren-compare_btn {
  margin-top: 30px;
}

.compare-table .table tbody tr td .compare-pdoduct-image .uren-compare_btn:hover {
  color: #ffffff !important;
}

.compare-table .table tbody tr td .compare-product-name {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
}

.compare-table .table tbody tr td .compare-product-name a {
  -webkit-transition-property: color;
  -o-transition-property: color;
  transition-property: color;
}

.compare-table .table tbody tr td .compare-product-name a:hover {
  color: #ffc400;
}

.compare-table .table tbody tr td .rating-box > ul > li {
  display: inline-block;
}

/*----------------------------------------*/
/*  38 - Pages - Contact
/*----------------------------------------*/
/* ---Google Map Related Stuff--- */
#google-map {
  height: 400px;
}

/* ---Contact Main Page--- */
.contact-main-page {
  padding: 80px 0 60px;
  /* ---Contact Form Area--- */
}

.contact-main-page .contact-page-side-content {
  background-color: #f4f4f4;
  padding: 30px 30px 19px;
  margin-top: 80px;
}

@media (max-width: 479px) {
  .contact-main-page .contact-page-side-content {
    padding: 25px 30px 19px;
  }
}

.contact-main-page .contact-page-side-content .contact-page-message {
  margin-bottom: 0;
  padding-bottom: 25px;
}

.contact-main-page .contact-page-side-content .single-contact-block {
  padding-bottom: 3px;
  margin-bottom: 27px;
  border-bottom: 1px solid #e5e5e5;
}

.contact-main-page .contact-page-side-content .single-contact-block:last-child {
  border-bottom: none;
}

.contact-main-page .contact-page-side-content .single-contact-block h4 {
  margin-bottom: 0;
  padding-bottom: 25px;
}

.contact-main-page .contact-page-side-content .single-contact-block p {
  margin-bottom: 0;
  padding-bottom: 20px;
}

.contact-main-page .contact-page-side-content .single-contact-block.last-child > h4 {
  padding-bottom: 20px;
}

.contact-main-page .contact-page-title {
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 20px;
}

@media (max-width: 479px) {
  .contact-main-page .contact-page-title {
    font-size: 25px;
  }
}

.contact-main-page .contact-form-content {
  margin-top: 75px;
}

.contact-main-page .contact-form-content .contact-form .form-group {
  margin-bottom: 20px;
}

.contact-main-page .contact-form-content .contact-form .form-group.form-group-2 {
  margin-bottom: 25px;
}

.contact-main-page .contact-form-content .contact-form .form-group label {
  display: block;
  font-size: 15px;
  line-height: 29px;
  font-weight: 400;
  margin-bottom: 20px;
  cursor: text;
}

.contact-main-page .contact-form-content .contact-form .form-group label span, .contact-main-page .contact-form-content .contact-form .form-group label.required {
  color: #ff0000;
}

.contact-main-page .contact-form-content .contact-form .form-group input {
  background-color: #ffffff;
  width: 100%;
  height: 50px;
  line-height: 50px;
  outline: none;
  border: 1px solid #e5e5e5;
  padding-left: 20px;
}

.contact-main-page .contact-form-content .contact-form .form-group textarea {
  background-color: #ffffff;
  width: 100%;
  outline: none;
  border: 1px solid #e5e5e5;
  padding-left: 20px;
  line-height: 50px;
  height: 200px;
}

.contact-main-page .contact-form-content .contact-form .form-group .uren-contact-form_btn {
  line-height: 12px;
  display: inline-block;
  padding: 16px 30px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  background-color: #999999;
  border: 1px solid #999999;
  color: #ffffff;
  text-transform: uppercase;
}

.contact-main-page .contact-form-content .contact-form .form-group .uren-contact-form_btn:hover {
  background-color: #ffc400;
  color: #ffffff;
  border-color: #ffc400;
}

/*----------------------------------------*/
/*  39 - Pages - About Us
/*----------------------------------------*/
.about-us-area {
  padding-top: 80px;
}

@media (max-width: 767px) {
  .about-us-area .overview-content {
    padding-top: 25px;
  }
}

.about-us-area .overview-content > h2 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 25px;
  padding-bottom: 20px;
  position: relative;
  text-transform: uppercase;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-area .overview-content > h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-area .overview-content > h2 {
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  .about-us-area .overview-content > h2 {
    font-size: 20px;
  }
}

@media (max-width: 479px) {
  .about-us-area .overview-content > h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

.about-us-area .overview-content > h2 span {
  color: #ffc400;
}

.about-us-area .overview-content > h2:before {
  background-color: #f4f4f4;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%;
}

.about-us-area .overview-content > h2:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: #ffc400;
  height: 2px;
  width: 170px;
}

.about-us-area .overview-content > .short_desc {
  margin-bottom: 0;
  padding-bottom: 25px;
}

@media (max-width: 767px) {
  .about-us-area .overview-content .uren-about-us_btn-area {
    float: left;
    width: 100%;
  }
}

.about-us-area .overview-content .uren-about-us_btn-area .about-us_btn {
  background: #ffc400;
  color: #ffffff;
  display: block;
  float: left;
  height: 50px;
  line-height: 50px;
  width: 145px;
  text-align: center;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.about-us-area .overview-content .uren-about-us_btn-area .about-us_btn:hover {
  background: #999999;
  color: #ffffff !important;
}

.project-count-area {
  padding-top: 75px;
}

.project-count-area .single-count:hover .count-icon span {
  animation: 850ms ease-in-out 0s normal none 1 running tada;
}

.project-count-area .single-count .count-icon span {
  font-size: 50px;
  color: #595959;
  line-height: 1;
  display: inline-block;
}

.project-count-area .single-count .count-title h2 {
  color: #ffc400;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.2px;
  margin: 15px 0 5px;
}

.project-count-area .single-count .count-title > span {
  font-size: 20px;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-count-area [class*="col-"]:nth-child(-n + 2) .single-count {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-count-area [class*="col-"]:nth-child(-n + 2) .single-count {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .project-count-area [class*="col-"] {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .project-count-area [class*="col-"]:last-child {
    padding-bottom: 0;
  }
}

.team-area {
  padding-top: 70px;
  padding-bottom: 80px;
}

.team-area .section_title-2 {
  padding-bottom: 25px;
}

.team-area .section_title-2 > h3 {
  margin-bottom: 0;
}

.team-area .team-member .team-thumb img {
  width: 100%;
  display: block;
  overflow: hidden;
}

.team-area .team-member .team-content {
  padding-top: 25px;
}

.team-area .team-member .team-content h3 {
  font-size: 20px;
  line-height: 28px;
  text-transform: capitalize;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.team-area .team-member .team-content p {
  margin-bottom: 0;
  padding-bottom: 15px;
}

.team-area .team-member .team-content .uren-social_link {
  padding-top: 20px;
}

.team-area .team-member .team-content .uren-social_link > ul > li {
  padding-right: 5px;
}

.team-area .team-member .team-content .uren-social_link > ul > li > a {
  width: 35px;
  height: 35px;
  line-height: 33px;
}

@media (max-width: 1199px) {
  .team-area .team-member .team-content .uren-social_link > ul > li > a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-area [class*="col-"]:nth-child(-n + 2) .team-member {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team-area [class*="col-"]:nth-child(-n + 2) .team-member {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .team-area [class*="col-"]:nth-child(-n + 3) .team-member {
    padding-bottom: 30px;
  }
}

/*----------------------------------------*/
/*  40 - Pages - FAQ
/*----------------------------------------*/
.frequently-area {
  padding: 75px 0 65px;
}

.frequently-area .frequently-content .frequently-desc {
  margin-bottom: 25px;
}

.frequently-area .frequently-content .frequently-desc h3 {
  font-weight: 400;
  text-transform: capitalize;
  padding-bottom: 15px;
  line-height: 32px;
  font-size: 25px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .frequently-area .frequently-content .frequently-desc h3 {
    font-size: 20px;
  }
}

.frequently-area .frequently-content .frequently-desc p {
  margin: 0;
  font-size: 14px;
}

.frequently-area .frequently-accordion .actives {
  border: 1px solid #ffc400;
}

.frequently-area .frequently-accordion .card {
  border-radius: 0;
  margin-bottom: 15px;
}

.frequently-area .frequently-accordion .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: #f4f4f4;
  border: 0;
}

.frequently-area .frequently-accordion .card-header a {
  font-weight: 400;
  padding: 15px 10px 15px 25px;
  text-transform: capitalize;
  line-height: 24px;
  cursor: pointer;
  display: block;
}

@media (max-width: 767px) {
  .frequently-area .frequently-accordion .card-header a {
    font-size: 14px;
  }
}

.frequently-area .frequently-accordion .card-header a:hover {
  color: #ffc400 !important;
}

.frequently-area .frequently-accordion .card-header a.collapsed:before {
  content: "\f067";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  position: absolute;
  right: 15px;
  top: 15px;
  color: #bababa;
}

@media (max-width: 479px) {
  .frequently-area .frequently-accordion .card-header a.collapsed:before {
    display: none;
  }
}

.frequently-area .frequently-accordion .card-header a:after {
  content: "\f068";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  position: absolute;
  right: 15px;
  top: 15px;
  color: #bababa;
}

@media (max-width: 479px) {
  .frequently-area .frequently-accordion .card-header a:after {
    display: none;
  }
}

/*----------------------------------------*/
/*  41 - Pages - 404
/*----------------------------------------*/
.error404-area {
  padding: 60px 0 80px;
}

.error404-area .search-error-wrapper h1 {
  color: #ffc400;
  font-size: 120px;
  margin-bottom: 0;
  padding-bottom: 22px;
}

.error404-area .search-error-wrapper h2 {
  font-size: 28px;
  margin-bottom: 0;
  padding-bottom: 20px;
  color: inherit;
}

@media (max-width: 479px) {
  .error404-area .search-error-wrapper h2 {
    font-size: 25px;
  }
}

.error404-area .search-error-wrapper > p {
  margin: 0 auto 0;
  max-width: 520px;
  font-size: 16px;
}

.error404-area .search-error-wrapper .error-form .inner-error_form {
  position: relative;
  width: 275px;
  margin: 22px auto 30px;
}

.error404-area .search-error-wrapper .error-form .inner-error_form input {
  border: 1px solid #e5e5e5;
  font-size: 13px;
  position: relative;
  width: 270px;
  height: 40px;
  line-height: 40px;
  padding: 0 40px 0 15px;
}

.error404-area .search-error-wrapper .error-search_btn {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 7px 20px;
  position: absolute;
  right: 0;
  top: 0;
  color: inherit;
}

.error404-area .search-error-wrapper .error-search_btn:hover {
  color: #ffc400;
}

.error404-area .search-error-wrapper .uren-error_btn {
  background: #ffc400;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  line-height: 43px;
  padding: 0 30px;
  text-transform: uppercase;
}

.error404-area .search-error-wrapper .uren-error_btn:hover {
  background: #999999;
  color: #ffffff !important;
}
